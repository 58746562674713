import React from 'react';

import LicenseAgreement from './LicenseAgreement';
export default function TermsAndCondition() {


return(
    <div>
    <LicenseAgreement />
    </div>
)

}