
// FreelancerAgreement.js
import React from "react";
import "./FreelancerAgreement.scss";

const FreelancerAgreementSwe = () => {
  return (
    <div className="agreement-container">
      <h1 className="agreement-title">FRILANSAVTAL FÖR RECEPTSKAPARE</h1>
      <p className="agreement-intro">
        <strong>Detta frilansavtal ("Avtalet")</strong> ingås mellan:
      </p>
      <ul className="agreement-parties">
        <li>
          <strong>Hellström Trading i Göteborg AB</strong> (ett företag registrerat i Sverige med
          organisationsnummer <strong>556572-0413</strong> och säte i <strong>Göteborg, Sverige</strong>.
          Hädanefter kallat ”Företaget” eller ”Vi”).
        </li>
        <li>
          <strong>Receptskaparen</strong> (hädanefter kallad ”Licensgivaren” eller ”Du”).
        </li>
      </ul>
      <hr />

      <section>
        <h2>1. DEFINITIONER</h2>
        <ul>
          <li>
            <strong>Plattform</strong>: Hänvisar till <em>www.bakesoft.se</em> och <em>live.bakesoft.se</em>, som ägs och drivs av Företaget.
          </li>
          <li>
            <strong>Recept</strong>: Unika matrecept eller innehåll som du skapar, inklusive text, bilder eller videor.
          </li>
          <li>
            <strong>Licensgivaren</strong>: Frilansaren som laddar upp recept till Plattformen och ger Företaget en licens att använda dem.
          </li>
          <li>
            <strong>Slutanvändare</strong>: Användare som betalar för att låsa upp recept på live.bakesoft.se.
          </li>
          <li>
            <strong>Intäkter</strong>: Betalningar som erhålls från Slutanvändare för att låsa upp recept.
          </li>
        </ul>
      </section>

      <section>
        <h2>2. FÖRHÅLLANDE MELLAN PARTERNA</h2>
        <p>
          Du bekräftar att du är en <strong>oberoende uppdragstagare</strong> och <strong>inte en anställd</strong> hos Företaget.
          Detta avtal skapar inte något anställnings-, partnerskaps- eller agentförhållande.
        </p>
      </section>

      <section>
        <h2>3. LICENSIERING OCH ÄGANDE</h2>
        <p>
          Du behåller alla immateriella rättigheter till dina recept. Genom att ladda upp recept till Plattformen ger du Företaget en
          <strong>icke-exklusiv, global, royaltydelande licens</strong> att:
        </p>
        <ul>
          <li>Visa, dela och distribuera receptet på Plattformen.</li>
          <li>Tillåta slutanvändare att se och få tillgång till receptet efter betalning.</li>
        </ul>
      </section>

      <section>
        <h2>4. BETALNINGSVILLKOR</h2>
        <ul>
          <li>
            <strong>Intäktsfördelning</strong>: Du får <strong>30% av nettotintäkterna</strong>. En
            <strong>transaktionsavgift på 1 USD</strong> tillämpas.
          </li>
          <li>
            <strong>Betalningsmetod</strong>: Utbetalningar sker endast till din angivna <strong>PayPal</strong>-e-postadress.
          </li>
          <li>
            <strong>Betalningsschema</strong>: Minsta utbetalningsbelopp är <strong>5 USD</strong>.
          </li>
        </ul>
      </section>

      <section>
        <h2>5. LICENSGIVARENS ANSVAR</h2>
        <p>Du godkänner att:</p>
        <ul>
          <li>Ladda upp originalinnehåll som du äger.</li>
          <li>Säkerställa att innehållet följer upphovsrättslagar.</li>
          <li>Rapportera dina intäkter och följa skatteregler.</li>
        </ul>
      </section>

      <section>
        <h2>6. FÖRETAGETS RÄTTIGHETER OCH SKYLDIGHETER</h2>
        <ul>
          <li>Granska och ta bort recept som bryter mot riktlinjer.</li>
          <li>Avbryta konton vid missbruk eller bedrägeri.</li>
        </ul>
      </section>

      <section>
        <h2>7. ANSVARSBEGRÄNSNING</h2>
        <p>
          Företaget är inte ansvarigt för förlorade intäkter på grund av tekniska fel, slutanvändares agerande eller tredje parts krav.
        </p>
      </section>

      <section>
        <h2>8. FORCE MAJEURE</h2>
        <p>
          Ingen part är ansvarig för förseningar orsakade av naturkatastrofer, strejker, pandemier eller andra oförutsägbara händelser.
        </p>
      </section>

      <section>
        <h2>9. AVTALSUPPSÄGNING</h2>
        <p>
          Vardera parten kan säga upp avtalet med <strong>30 dagars skriftligt meddelande</strong>. Eventuella obetalda intäkter kommer att behandlas.
        </p>
      </section>

      <section>
        <h2>10. TILLÄMPLIG LAG OCH TVISTER</h2>
        <p>Detta avtal regleras av <strong>Sveriges lag</strong>.</p>
      </section>

      <footer className="agreement-footer">
        <p>
          Genom att kryssa i rutan <em>”Jag godkänner frilansavtalet”</em> bekräftar du att du har läst, förstått och godkänt avtalsvillkoren.
        </p>
        <p><strong>Hellström Trading i Göteborg AB</strong></p>
        <p>Organisationsnummer: 556572-0413</p>
        <p>Adress: Göteborg, Sverige</p>
      </footer>
    </div>
  );
};

export default FreelancerAgreementSwe;
