
import React,{useState,useEffect,useContext, useRef} from 'react'
import languages from '../AppLanguage/AppLanguage';
import AppError from '../AppLanguage/AppError';
import { RotatingLines } from 'react-loader-spinner'
import Alert from '../functions/appAlert'
import useDeviceDetection from '../hooks/useDeviceDetection'
import useReportErr from '../hooks/useReportErr'
import {useNavigate,useLocation,useParams} from 'react-router-dom';
import useFetchImageUrl from '../hooks/useFetchImageUrl';
import usePageSettings from '../hooks/usePageSettings';
import { saveAs } from 'file-saver';
import axios from 'axios';
import { CgMenuRound } from "react-icons/cg";
import { AiOutlineClose } from "react-icons/ai";
import {CheckCircle2, Mail } from "lucide-react";

//stripe
import appearance from '../Stripe/StripeAppearance';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import {PaymentElement,useStripe,useElements} from "@stripe/react-stripe-js";

import {useGetData,useDeleteData,usePostData,usePutData,usePostData1} from '../hooks/useGetData'
import './MyAccount.css'
import '../style/sliders.css'
import { FaFilePdf } from "react-icons/fa";
import klarnaimage from '../images/klarna.png'
import {XCircle } from 'lucide-react';
import { IoReceiptOutline } from "react-icons/io5";
import { MdOutlinePending } from "react-icons/md";
import { LuConstruction } from "react-icons/lu";
import { SlCloudUpload } from "react-icons/sl";
import UserSales from './UserSales'
import noimagefound from '../images/noimagefound.jpg'
import { TbBrandPaypal } from "react-icons/tb";
import FreelancerAgreementEng from '../TermsAndCondition/FreelanceAgreementEng';
import FreelancerAgreementSwe from '../TermsAndCondition/FreelanceAgreementSwe';
import FreelancerAgreementJap from '../TermsAndCondition/FreelanceAgreementJap';
import RecipePublisherSignup from './RecipePublisherSignup';


//<XCircle

export const MyContext = React.createContext();

const stripePromise = loadStripe("pk_live_51Q2BZPP8AlL8ItFuETVcJBoEneSZaLM4l1QGDdr51MBxxAyeiLsSweubonnCbGWXGoZLs7ZmUNqPeIzdZqxmfrQQ00EmBSFMyE");


export default function MyAccount() {
    let location = useLocation();
    const query = new URLSearchParams(location.search);

    



    const[getBackGroundShade,setBackGroundShade]=useState(0.45)
    const[getRenderKey,setRenderKey]=useState(0) // this is the key for the component
    const[getMenuShade,setMenuShade]=useState(0.75)
    const[getMenuShade2,setMenuShade2]=useState(0.45)

    const [windowWidth,setWindowWith]=useState(0) // this is the window width
    const [windowHeight,setWindowHeight]=useState(0)  // this is the window height
    const [getMobileIsUsed,setMobileIsUsed]=useState(false) // this is the window height
    const[getCountry,setCountry]=useState('se') // this is the country
    const[getIpadIsUsed,setIpadIsUsed]=useState(false) // this is the ipad
    const [getColorVal,setColorVal]=useState('')
    const [getImageVal,setImageVal]=useState('')
    const [getImageUrl,setImageUrl]=useState('')
    const[getAccount,setAccount]=useState([{}]) // this is the account
    const[getSpinnerLoading,setSpinnerLoading]=useState(false)
    const [getAlertMessage,setAlertMessage] = useState({});
    const[getAlertCode,setAlertCode]=useState(0)
    const [getShowAlert,setShowAlert] = useState(false);
    const [getButtonClicked,setButtonClicked]=useState(7)
    const[getName,setName]=useState('')
    const[getSurname,setSurname]=useState('')
    const[getAddress,setAddress]=useState('')
    const[getZipcode,setZipcode]=useState('')
    const[getCity,setCity]=useState('')
    const[getRegion,setRegion]=useState('')
    const[getPhone,setPhone]=useState('')
    const[getEmail,setEmail]=useState('')
    const[getPassword,setPassword]=useState('')
    const[getPassword2,setPassword2]=useState('')
    const[getDisposableHeight,setDisposableHeight]=useState(0)
    const[getForetagName,setForetagName]=useState('')
    const[getViewDataUsage,setViewDataUsage]=useState(false)
    const[getUserDataUsage,setUserDataUsage]=useState([{}])
    const[getUserMainData,setUserMainData]=useState([{}])
const[getUserInfo,setUserInfo]=useState([{}])
const[getconsent,setconsent]=useState(false)
const [getOldpass,setOldpass]=useState('')
const [getNewpass,setNewpass]=useState('')
const [getConfirmpass,setConfirmpass]=useState('')
const [getUserAccountBalance,setUserAccountBalance]=useState([{}])  // user account balance
const [getInvoiceNextDueDate,setInvoiceNextDueDate]=useState([{}]) // invoice due date
const [getUserInvoiceInfo,setUserInvoiceInfo]=useState([{}]) // user invoice information & historical
const [getUserBillingAdressInfo,setUserBillingAdressInfo]=useState([{}]) // user billing adress information
const [getTaxId,setTaxId]=useState('')
const [getMaxValueSlider,setMaxValueSlider]=useState(false)
const [getMaxValue,setMaxValue]=useState()
const[getAccountInformation,setAccountInformation]=useState([{}])
const[getServiceInformation,setServiceInformation]=useState([{}])
const[getInvoiceRowInfo,setInvoiceRowInfo]=useState([{}])
const[getServicePriceInformation,setServicePriceInformation]=useState([{}])
const[getCurrencyInfo,setCurrencyInfo]=useState([{}])
const [getServicesInformation,setServicesInformation]=useState([{}])
const[getchosenServiceid,setchosenServiceid]=useState(0)
const[expandedRow,setExpandedRow]=useState(null)
const[getServicesSalesInformation,setServicesSalesInformation]=useState([{}])
const[getBillingData,setBillingData]=useState([])
const[getOwningCompanyInfo,setOwningCompanyInfo]=useState([{}])
const[getchosenInvoiceId,setChosenInvoiceId]=useState(0)
const[getBillingTotalAmount,setBillingTotalAmount]=useState([{}])
const[getUserTotalUsage,setUserTotalUsage]=useState([{}])
const[getSupportCategories,setSupportCategories]=useState([{}])
const[getSupportCategory,setSupportCategory]=useState(0)
const[getSupportMessage,setSupportMessage]=useState('')
const[getSupportTickets,setSupportTickets]=useState([{}])
const[getSupportTicket,setSupportTicket]=useState([{}])
const[getTotalCurrencyInfo,setTotalCurrencyInfo]=useState([{}])
const [menuOpen, setMenuOpen] = useState(false);
const[getUserMonthlyUsageCost,setUserMonthlyUsageCost]=useState([{}])
const [getVatInfo,setVatInfo]=useState([{}])
const[getKlarna,setKlarna]=useState(false)
const[getChosenTicketId,setChosenTicketId]=useState(0)
const[creatorImage,setCreatorImage]=useState('')
const[creatorLivePresentation,setCreatorLivePresentation]=useState([{}])
const[creatorProfileText,setCreatorProfileText]=useState('')
const[userSalesTotalMonth,setUserSalesTotalMonth]=useState([{}])
const[userTotalSales,setUserTotalSales]=useState([{}])
const[userTotalSalesInMonth,setUserTotalSalesInMonth]=useState([{}])
const[userKreditComission,setUserKreditComission]=useState([{}])
const[bakesoftPayoutCharge,setBakesoftPayoutCharge]=useState('')
const[bakesoftKreditPrice,setBakesoftKreditPrice]=useState('')
const[creatorPaypalEmail,setCreatorPaypalEmail]=useState('')  
const[isChecked,setIsChecked]=useState(false)
const[paypalEmailAcceptInfo,setPaypalEmailAcceptInfo]=useState([{}])

// recipe publisher setup
  const [step, setStep] = useState(1);
  const [image, setImage] = useState(null);
  const [profileText, setProfileText] = useState('');
  const[paypalEmail,setPaypalEmail]=useState('')
  const[agreedToTerms,setAgreedToTerms]=useState(false)
  const[isLoading,setIsLoading]=useState(false)
  const[preview,setPreview]=useState(null)


              // file handling
                const maxSize = 3 * 1024 * 1024; // 3 Megabytes
                const acceptedFileTypes = ['image/jpeg', 'image/png'];
                const [file, setFile] = useState(null);
                const [error, setError] = useState(null);
                // end file handling
                const [trigger, setTrigger] = useState(false); 
                const[getTabId,setTabId]=useState(0)
                // range text
                const [rangeText, setRangeText] = useState({
                  "-2": "25%",
                  "-1": "50%",
                  "0": "0",
                  "1": "1",
                  "2": "2",
                  "3": "3",
                  "4": "4",
                  "5": "5",
                  "6": "6",
                  "7": "7"
                  
                });


// klarna payment
const [getchosenKlarnaServiceid,setchosenKlarnaServiceid]=useState(0)
const [getChosenKlarnaServiceName,setChosenKlarnaServiceName]=useState('')
const [getChosenKlarnaTotalSum,setChosenKlarnaTotalSum]=useState(0)


//klarna payment status callback
const [paymentStatus, setPaymentStatus] = useState(null);

//klarna session token
const [clientToken, setClientToken] = useState(null);
const pageid=21

//Stripe payment
const [getInvoices,setInvoices]=useState([{}])
const[getChosenInvoice,setChosenInvoice]=useState('')
const[getInvoiceRows,setInvoiceRows]=useState([{}])
const [clientSecret, setClientSecret] = useState("");
const [isPaymentComplete, setIsPaymentComplete] = useState(false);
const[getInfoStatus,setInfoStatus]=useState(false)
const [getPaymentDetails, setPaymentDetails] = useState(null);
const [getButtonId, setButtonId] = useState(0);
const[getStatus,setStatus]=useState('')



//stripe payment

const callbackClientSecret = query.get('client_secret');
const paymentIntent = query.get('payment_intent');
const paymentIntentClientSecret = query.get('payment_intent_client_secret');
const redirectStatus = query.get('redirect_status'); // 'succeeded' or 'failed'


//payment status direction for user
const paymentredirect = query.get('paystatus'); // 'succeeded' or 'failed'


// Fetch additional payment details from backend if necessary, and we also make the setup
// of the users account if the payment is successful and is done by a callback supplier.
// A callback supplier is a supplier who sends the onsuccess event via callback.
// This payment check function is striggered by the callback.
// When the callback comes we read the params, and if we have a payment intent a useeffect is triggered
// this useeffect triggers the payment check function. When a payment intent is done we send with it a 
// a unique id, this id we will pick up when we verify at stripe, in this way  we can know which user
// has done the payment and we can update the user payment status in the database.
async function PaymentCheck() {
  setSpinnerLoading(false)
  try{
    let response=await usePostData(`payment-detailsServices`,{intent:paymentIntent});
    if(response.status === 200) {
      setPaymentDetails(response.data);
      setStatus(response.data.status)
      await FetchUserDataUsage()
      setButtonClicked(15)
  
  }else if(response.status !== 200){
    setButtonClicked(16)
  }
}catch(error){
  
    setButtonClicked(16)
}
}

useEffect(() => {
  if(paymentIntent) {
  PaymentCheck();
  }

}, [paymentIntent]);
// end stripe payment







    useEffect(()=>{
        let country = localStorage.getItem('country')||'en' // this is the country code
        setCountry(country)
      },[])

      // if language is japanese we use the font Zen Old Mincho
        let newFontFamily=''
        if(getCountry==='ja'){
        newFontFamily= "'Zen Old Mincho', Arial, Helvetica, sans-serif"
        }else{
        newFontFamily='Arial, Helvetica, sans-serif'
        }
        document.documentElement.style.setProperty('--font-family', newFontFamily);

      const { GetSettings, backGroundShade, menuShade, menuShade2, colorVal,imageVal } = usePageSettings(pageid);
      const { imageUrl, alertMessage, alertCode, showAlert } = useFetchImageUrl(getImageVal); // runs at mount, gives ut the image url and fetch image from server

      const navigate = useNavigate();
//1745x870
      useEffect(() => {
        // Function to parse query parameters
        const queryParams = new URLSearchParams(location.search);
        const status = queryParams.get('klarnastatus'); 

        if (status) {
            setPaymentStatus(status);
        }
    }, [location.search]); 

      useEffect(() => {
        function setSize() {
          var totalHeight=window.innerHeight

            setDisposableHeight(totalHeight-150)
          setWindowWith(window.innerWidth);
          setWindowHeight(window.innerHeight);
          if(window.innerWidth<600){
            setMobileIsUsed(true)
          }
        }
        setSize();

      }, [backGroundShade, menuShade, menuShade2, colorVal,imageVal]);

// set the passowrds to void
useEffect(()=>{
    setOldpass('')
    setNewpass('')
    setConfirmpass('')
},[])

// language settings
const selectedCountry = languages[getCountry];

const{
    title,
    websiteCompanyName,
invoiceText,
broughtToYouText,
customerIdText,
serviceText,
nameText,
numText,
maxText,
debitText,
kreditText,
totaltText,
sumNetText,
sumVatText,
attBetalaText,
bankgiroText,
swishText,
termsText,
bankText,
bicText,
ibanText,
userFirstName,
userFamilyName,
userCompany,
userAdress,
userPostalCode,
userCity,
userRegion,
userCountry,
userPhone,
userEmail,
userSaveButtonText1,
userallServices,
userService,
userPrice,
userActivate,
numPageRequestsPerPage,
userPagenr,
userPageComponent,
userPageNum,
userMyData,
userMyDataDescriptionText,
userDataId,
userDataName,
userDataAntal,
userDataIngredientCategories,
userDataRecipeCategories,
userDataProductCategories,
userDataIngredient,
userDataRecipes,
userDataProducts,
userDataProductions,
userDataRecipeImages,
userDataProductImages,
userDataInstructionText,
useDataMyDataButtonText,
paymentPayPal,
paymentSwish,
paymentCreditCard,
paymentInvoice,
accountOverview,
userAccount,
accountTransactionsFor,
accountService,
accountInvoice,
accountDebit,
accountKredit,
accountDate,
accountSystemComment,
accountTotalDebit,
accountTotalKredit,
accountTotalSaldo,
estimatedSaldo,
estimatedSaldoText,
nextPaymenttext,
prePaymentText,
totalAmountUsed,
invoiceHistoryText,
invoiceHistoryInvoice,
invoiceHistorySum,
invoiceHistoryDate,
invoiceHistoryStatus,
chooseInvoiceText,
invoiceRowsText,
allInvoiceRowsText,
invoiceRowsId,
invoiceRowsService,
invoiceRowsMax,
invoiceRowsRequest,
invoiceRowsDebitQty,
invoiceRowsPrice,
invoiceRowsTotal,
invoiceRowsType,
invoiceRowsTotalSum,
passwordValidText,
newPasswordText,
confirmPasswordText,
showPasswordText,
savePasswordButtonText,
iforgotMyPasswordText,
supportText,
settingsHeaderText,
companyAndPersonalText,
addressInstructionText,
taxIdentificationVATText,
taxIdInformationText,
registerTaxIdButtonText,
maxValueWarningText,
actiVateMaxValueText,
maxValueButtonSaveText,
addressInfoButtontext,
servicesButtontext,
dataUsageButtontext,
mydataButtontext,
paymentButtontext,
myAccountButtonText,
myInvoiceButtonText,
passwordButtontext,
supportButtontext,
settingsButtontext,
servicesButtonUsagetext,
userStat,
serviceHeaderText,
  servicePrisHeaderText,
  serviceActivateHeaderText,
  serviceStatusHeaderText,
  debserviceidText,
debserviceBenaemningText,
debserviceQtyText,
debserviceMaxText,
debserviceUsableText,
debserviceStatusText,
supportMText,
supportMessageText,
supportMessageSelectText,
supportMessageSendText,
supportHistoryServiceIdText,
supportHistoryDateText,
supportHistoryStatusText,
supportHistoryCategoryText,
messagesSentToSupportText,
passwordHeaderText,
resetPasswordHeaderText,
overviewText,
overviewInformationText,
overviewInvoiceText,
invoiceSelectFirstOption,
printInvoceButtonText,
userDebitServices,
myinformationText,
paymentKlarna,
supportButtonAnswerText,
supportTitleText,
supportHistoryBenaemnText,
paymentStandard,
paymentIntentErrorText,
thankYouPurchaseText,
serviceActivatedText,
paymentFailedMessageText2,
paymentFailedMessageText,
isPayedText,
isNotPayedText,
choseImageButtontext,
choseImageUploadText,
liveAccountSetupText,
liveCreatorImageText,
liveCreatorPublicImageText,
liveCreatorProfileText,
liveMax500text,
saveCreatorText,
liveAccountText,
profileIsCreatedText,

}=selectedCountry.MyAccount||{};

const {
    paypalMainHeaderText,
    klarnaMainHeaderText,
    pptotalAmountToPayText,
    ppChosenServiceText,
    ppChosenCurrencyText,
    registerAccountTabText,
    paymentTabText,
    ppBakesoftTermsText,
    ppBakesoftTerms,
    ppSelectInvoiceText,
    ppSelectCurrencyText,

}=selectedCountry.info||{};


const {
 liveAreaText,
}=selectedCountry.liveArea||{};

const selectedError=AppError[localStorage.getItem('country')||'en']

const {
    emailSent,
    userAddressDataUpdated,
    activateServiceMessage,
    deactivateServiceMessage,
    prescriptionCreatedMessage,
    supportMessageSendText1,
    dowloadpdfOkMessage,
    paypalPaymentConfirmText,
    klarnaPaymentSuccessText,
    klarnaPaymentfailureText,
    clientSideUpdateSupportTicketText,
    noinvoicesFoundText,
}= selectedCountry.confirmationMessages||{};

const{
    paymentSucceededText,
    paymentFailedText
}= selectedCountry.info||{};


//end language settings

useEffect(() => {
    GetSettings();
  }, [GetSettings]);

  // we set the image url here
  useEffect(() => {
    setImageUrl(imageUrl);
    if (alertCode > 0) {
      setAlertMessage(alertMessage);
      setAlertCode(alertCode);
      setShowAlert(showAlert);
    }
  }, [imageUrl]);

  // here we set the color values and image values
  useEffect(() => {
    setBackGroundShade(backGroundShade);
    setMenuShade(menuShade);
    setMenuShade2(menuShade2);
    setColorVal(colorVal);
    setImageVal(imageVal);
  }, [backGroundShade, menuShade, menuShade2, colorVal,imageVal]);





function rgbToRgba(rgb, alpha) {
    return rgb.replace(')', `, ${alpha})`).replace('rgb', 'rgba');
  }

//--bakcground shade settings
let rgbaVal
let rgbaVal2
let val

if(getColorVal && getColorVal.length > 0){
  let rgb = getColorVal;
  let alpha = getMenuShade; // replace with your alpha value
  let alpha2 = getMenuShade2; // replace with your alpha value
  rgbaVal = rgbToRgba(rgb, alpha);
  rgbaVal2 = rgbToRgba(rgb, alpha2);
  val=`rgba(255, 255, 255, ${getBackGroundShade})`

  }else{
    rgbaVal =`rgba(255, 255, 255, ${getBackGroundShade})`;
    rgbaVal2 =`rgba(255, 255, 255, ${getBackGroundShade})`;
    val=`rgba(255, 255, 255, ${getBackGroundShade})`
  }
//---

useEffect(() => {
    let root = document.documentElement;
    root.style.setProperty('--rgbaVal', rgbaVal);
    root.style.setProperty('--rgbaVal2', rgbaVal2);
    root.style.setProperty('--val',val);
    root.style.setProperty('--disposableHeight',getDisposableHeight+'px');
    root.style.setProperty('--windowHeight',windowHeight+'px');

  }, [getBackGroundShade,getMenuShade,getMenuShade2]);

  //-----

  // ErrHandling
  const RunReportErrHandler = async (errCode) => {
    setSpinnerLoading(true);

      await useReportErr('ErrReport',{errCode});
    setSpinnerLoading(false);
  };
  const handleCloseAlert = () => {
    setShowAlert(false);
  };
  useEffect(() => {
    if (getAlertCode > 0) {
      RunReportErrHandler(getAlertCode);
    }
  }, [getAlertCode]);


  // if the user changges the value on the range control we save the value in the database
async function SetBackGroundShadeHandler(val){
    setBackGroundShade(val)
    setRenderKey(getRenderKey+1)
    setSpinnerLoading(true)
    try{
      const response = await usePutData(`updateBackGroundShade`,{pageid,val})
      if(response.status===200){
      setSpinnerLoading(false)
      }
    }catch(error){
        setAlertCode(53001)
        setAlertMessage(selectedError[53001])
        setSpinnerLoading(false)
        setShowAlert(true)

        return;
      }

  }

  async function SetmenuColorHandler(val){
    setMenuShade(val)
    setMenuShade2(val-0.20)
    setRenderKey(getRenderKey+1)
    setSpinnerLoading(true)
    try{
      const response = await usePutData(`updateMenuShade`,{pageid,val})
      if(response.status===200){
      setSpinnerLoading(false)
      }

  }catch(error){
    setAlertCode(53002)
    setAlertMessage(selectedError[53002])
    setSpinnerLoading(false)
    setShowAlert(true)

    return;
  }
  }


useEffect(()=>{

FetchUserDataUsage()
},[getViewDataUsage,trigger])

async function FetchUserDataUsage(){
    setSpinnerLoading(true)
    try{
        const response = await useGetData(`getUserDataUsage`)

            setUserDataUsage(response.data[0])
            setUserAccountBalance(response.data[1])
            setInvoiceNextDueDate(response.data[2])
            setUserInvoiceInfo(response.data[3])
            setUserBillingAdressInfo(response.data[4])
            setMaxValue(response.data[4][0].invoiceMaxVal)
            setMaxValueSlider(response.data[4][0].invoiceMaxVal>0?true:false)
            setAccountInformation(response.data[5])
            setServiceInformation(response.data[6])
            setInvoiceRowInfo(response.data[7])
            setCurrencyInfo(response.data[8])
            setServicesInformation(response.data[9])
            setServicesSalesInformation(response.data[10])
            setOwningCompanyInfo(response.data[11])
            setUserTotalUsage(response.data[12])
            setSupportCategories(response.data[13])
            setSupportTickets(response.data[14])
            setTotalCurrencyInfo(response.data[15])
            setUserMonthlyUsageCost(response.data[16])
            setVatInfo(response.data[17])
            setCreatorImage(response.data[18])
            setCreatorLivePresentation(response.data[19])
            setUserSalesTotalMonth(response.data[20])
            setUserTotalSales(response.data[21])
            setUserTotalSalesInMonth(response.data[22])
            setUserKreditComission(response.data[23])
            setBakesoftPayoutCharge(response.data[24][0].settingsValue||'')
            setBakesoftKreditPrice(response.data[25][0].price||'')
            setPaypalEmailAcceptInfo(response.data[26])

            



            setSpinnerLoading(false)

    }catch(error){
        setAlertCode(53000)
        setAlertMessage(selectedError[53000])
        setSpinnerLoading(false)
        setShowAlert(true)
        return;
    }
}


const buttonClickedHandler=(val)=>{
    setButtonClicked(val)
    setMenuOpen(false)
    if(val===4){
        if(getViewDataUsage===false){
    setViewDataUsage(true)

        }else{
            setViewDataUsage(false)
        }
    }else if(val===5){
        GetUserMainData()
    }else if(val===1){
        GetUserAdressInfo()
    }else if(val===6){
        GetInvoicesToPay()
    }else if(val===12){
        GetUserMainData()
    }


}

const setNameHandler=(val)=>{

    let myNewObjArr=[...getUserInfo]
    myNewObjArr[0].givenName=val
    setUserInfo(myNewObjArr)
}

const setSurNameHandler=(val)=>{
      // here we need to modify the object getUserInfo
        let myNewObjArr=[...getUserInfo]
        myNewObjArr[0].familyName=val
        setUserInfo(myNewObjArr)
}

const setAddressHandler=(val)=>{
      // here we need to modify the object getUserInfo
        let myNewObjArr=[...getUserInfo]
        myNewObjArr[0].address=val
        setUserInfo(myNewObjArr)

}

const setZipCodeHandler=(val)=>{
      // here we need to modify the object getUserInfo
        let myNewObjArr=[...getUserInfo]
        myNewObjArr[0].postalCode=val
        setUserInfo(myNewObjArr)

}

const  setCityHandler=(val)=>{
      // here we need to modify the object getUserInfo
        let myNewObjArr=[...getUserInfo]
        myNewObjArr[0].city=val
        setUserInfo(myNewObjArr)


}

const setPhoneHandler=(val)=>{
      // here we need to modify the object getUserInfo
        let myNewObjArr=[...getUserInfo]
        myNewObjArr[0].phone=val
        setUserInfo(myNewObjArr)

}

const setEmailHandler=(val)=>{
  // here we need to modify the object getUserInfo
    let myNewObjArr=[...getUserInfo]
    myNewObjArr[0].email=val
    setUserInfo(myNewObjArr)
}

const setPasswordHandler=(val)=>{
    setPassword(val)
}

const setPassword2Handler=(val)=>{
    setPassword2(val)
}

const setForetagNameHandler=(val)=>{
    let myNewObjArr=[...getUserInfo]
    myNewObjArr[0].company=val
    setUserInfo(myNewObjArr)
    setForetagName(val)
}

const saveUserAddressInfoHandler=()=>{
    SaveAdressInfo()
}
const setRegionHandler=(val)=>{
      // here we need to modify the object getUserInfo
        let myNewObjArr=[...getUserInfo]
        myNewObjArr[0].region=val
        setUserInfo(myNewObjArr)

}
const setCountryHandler=(val)=>{
      // here we need to modify the object getUserInfo
        let myNewObjArr=[...getUserInfo]
        myNewObjArr[0].country=val
        setUserInfo(myNewObjArr)

}



async function SendUserDataByEmail(){
    setSpinnerLoading(true)

    try{
        const response = await useGetData(`sendUserDataByEmail`)
        if(response.status===200){
            setSpinnerLoading(false)
            setAlertCode(0)
            setAlertMessage(emailSent)
            setShowAlert(true)
        }
    }catch(error){
        setSpinnerLoading(false)
        setAlertCode(53004)
        setAlertMessage(selectedError[53004])
        setShowAlert(true)
    }
}

async function GetUserMainData(){
    setSpinnerLoading(true)

    try{
        const response = await useGetData(`getUserMainData`)
       setUserMainData(response.data[0])
            setSpinnerLoading(false)
    }catch(error){
        setSpinnerLoading(false)
        setAlertCode(53005)
        setAlertMessage(selectedError[53005])
        setShowAlert(true)
    }
}

async function GetUserAdressInfo(){
    setSpinnerLoading(true)
    try{
        const response=await useGetData(`getUserAdressInfo`)
        setUserInfo(response.data[0])
        setSpinnerLoading(false)

    }catch(error){
        setSpinnerLoading(false)
        setAlertCode(53006)
        setAlertMessage(selectedError[53006])
        setShowAlert(true)
    }
}

async function SaveAdressInfo(){
    setSpinnerLoading(true)
    try{
        const response=await usePutData(`updateUserAdressInfo`,{getUserInfo})
        setSpinnerLoading(false)
        setAlertCode(0)
        setAlertMessage(userAddressDataUpdated)
        setShowAlert(true)
    }catch(error){
        setSpinnerLoading(false)
        setAlertCode(53007)
        setAlertMessage(selectedError[53007])
        setShowAlert(true)
    }
}

async function SavePrescriptionHandler(prescription){

    setSpinnerLoading(true)
    try{
        const response= await usePostData('savePrescription',{prescription,getconsent})
        if(response.status===200){
            setSpinnerLoading(false)
            setAlertCode(0)
            setAlertMessage(prescriptionCreatedMessage)
            setShowAlert(true)
        }

    }catch(error){
        setSpinnerLoading(false)
        setAlertCode(53009)
        setAlertMessage(selectedError[53009])
        setShowAlert(true)
    }
}

const consentHandler=(val)=>{
    setconsent(val)
}
const setNewPasswordHandler=(pass,val)=>{
    if(val===1){
        setOldpass(pass)
    }else if(val===2){
        setNewpass(pass)
    }else if(val===3){
        setConfirmpass(pass)
    }
}


async function SaveNewPassword(reset){
    console.log("save new password")
    setSpinnerLoading(true)
    try{
        const response= await usePutData('saveNewPassword',{getOldpass,getNewpass,getConfirmpass,reset})
        if(response.status===200){
            setSpinnerLoading(false)
            setAlertCode(0)
            setAlertMessage('Password has been updated')
            setShowAlert(true)
        }
    }catch(error){
        setSpinnerLoading(false)
        setAlertCode(53010)
        setAlertMessage(selectedError[53010])
        setShowAlert(true)
    }
}

async function SendPassByEmailToUser(){
    console.log("send pass by email")
    setSpinnerLoading(true)
    try{
        const response= await usePostData('sendPassByEmail',{getEmail})
        if(response.status===200){
            setSpinnerLoading(false)
            setAlertCode(0)
            setAlertMessage('Password has been sent to your email')
            setShowAlert(true)
        }
    }catch(error){
        setSpinnerLoading(false)
        setAlertCode(53011)
        setAlertMessage(selectedError[53011])
        setShowAlert(true)
    }
}

const taxIdHandler=(val)=>{
    setTaxId(val)
}



async function InvoiceMaxVal(){
    if(getMaxValueSlider===false){
        setMaxValue(0)
    }

        setSpinnerLoading(true)
       try{
        const response = await usePutData('invoiceMaxVal',{getMaxValueSlider,getMaxValue})
        console.log(response)
        if(response.status===200){
            if(getMaxValueSlider===false){
                setMaxValue(0)
                setAlertCode(0)
                setAlertMessage('Max värdes funktionen har inaktiverats')
            }else if(getMaxValueSlider===true){
                setAlertCode(0)
                setAlertMessage('Max värdes funktionen har aktiverats')
            }
            setShowAlert(true)
            setSpinnerLoading(false)
            }
         }catch(error){
            setAlertCode(53012)
            setAlertMessage(selectedError[53012])
            setShowAlert(true)
            setSpinnerLoading(false)
            return;
         }
}



const toggleMaxValueSlider = () => {
    setMaxValueSlider(prevState => {
        if (prevState) {
            setMaxValue(0);
        }
        return !prevState;
    });
};

async function CheckIfBaseService(val,serviceid){
    if(serviceid===127 || serviceid===128 || serviceid===133 || serviceid===134){
        CheckUserAccount(val,serviceid)
    }else{
        ServicesAddHandler(val,serviceid)
    }
}

async function CheckUserAccount(val,serviceid){
    setSpinnerLoading(true)
    try{
        const response = await useGetData(`checkUserAccount/${serviceid}`)
      
        if(response.status===200){
            setSpinnerLoading(false)
            ServicesAddHandler(val,serviceid)
        }else if(response.status===201){
            setAlertCode(0)
            setAlertMessage(response.data.textVal)
            setShowAlert(true)
            setSpinnerLoading(false)
        }
        
        
    }catch(error){
        setSpinnerLoading(false)
        setAlertCode(53008)
        setAlertMessage(selectedError[53008])
        setShowAlert(true)
    }

}

const ServicesAddHandler=(val,serviceid)=>{
// here we will write to the object getServicesInformation
let myNewObjArr=[...getServicesInformation]
// find the inedex of the row we want to update
// we have a special case here, if serviceid=127 or 128 we will switch them
// if serviceid =127 and the value is0 we will set 128 to 1 and we will set 127 to 0
// also we need to assure that133 and 134 are switched off to 0
if(serviceid===127 && val===false){
    let myindex = myNewObjArr.findIndex(x => x.serviceid === 128)
    myNewObjArr[myindex].Istatus=1
    myindex = myNewObjArr.findIndex(x => x.serviceid === 127)
    myNewObjArr[myindex].Istatus=0
    // so wee need to find 133 and 134 and set them to 0
    myindex = myNewObjArr.findIndex(x => x.serviceid === 133)
    myNewObjArr[myindex].Istatus=0
    myindex = myNewObjArr.findIndex(x => x.serviceid === 134)
    myNewObjArr[myindex].Istatus=0
    
}else if(serviceid===128 && val===false){
    let myindex = myNewObjArr.findIndex(x => x.serviceid === 127)
    myNewObjArr[myindex].Istatus=1
    myindex = myNewObjArr.findIndex(x => x.serviceid === 128)
    myNewObjArr[myindex].Istatus=0
     // so wee need to find 133 and 134 and set them to 0
     myindex = myNewObjArr.findIndex(x => x.serviceid === 133)
     myNewObjArr[myindex].Istatus=0
     myindex = myNewObjArr.findIndex(x => x.serviceid === 134)
     myNewObjArr[myindex].Istatus=0

}else if(serviceid===127 && val===true){
    let myindex = myNewObjArr.findIndex(x => x.serviceid === 128)
    myNewObjArr[myindex].Istatus=0
    myindex = myNewObjArr.findIndex(x => x.serviceid === 127)
    myNewObjArr[myindex].Istatus=1

     // so wee need to find 133 and 134 and set them to 0
     myindex = myNewObjArr.findIndex(x => x.serviceid === 133)
     myNewObjArr[myindex].Istatus=0
     myindex = myNewObjArr.findIndex(x => x.serviceid === 134)
     myNewObjArr[myindex].Istatus=0

}else if(serviceid===128 && val===true){
    let myindex = myNewObjArr.findIndex(x => x.serviceid === 127)
    myNewObjArr[myindex].Istatus=0
    myindex = myNewObjArr.findIndex(x => x.serviceid === 128)
    myNewObjArr[myindex].Istatus=1

     // so wee need to find 133 and 134 and set them to 0
     myindex = myNewObjArr.findIndex(x => x.serviceid === 133)
     myNewObjArr[myindex].Istatus=0
     myindex = myNewObjArr.findIndex(x => x.serviceid === 134)
     myNewObjArr[myindex].Istatus=0

// here we will also switch between 133 and 134 Bas Företag and Total Företag
}
else if(serviceid===133 && val===false){
    let myindex = myNewObjArr.findIndex(x => x.serviceid === 134)
    myNewObjArr[myindex].Istatus=1
    myindex = myNewObjArr.findIndex(x => x.serviceid === 133)
    myNewObjArr[myindex].Istatus=0

     // so wee need to find 127 and 128 and set them to 0
     myindex = myNewObjArr.findIndex(x => x.serviceid === 127)
     myNewObjArr[myindex].Istatus=0
     myindex = myNewObjArr.findIndex(x => x.serviceid === 128)
     myNewObjArr[myindex].Istatus=0
}else if(serviceid===134 && val===false){
    let myindex = myNewObjArr.findIndex(x => x.serviceid === 133)
    myNewObjArr[myindex].Istatus=1
    myindex = myNewObjArr.findIndex(x => x.serviceid === 134)
    myNewObjArr[myindex].Istatus=0

      // so wee need to find 127 and 128 and set them to 0
      myindex = myNewObjArr.findIndex(x => x.serviceid === 127)
      myNewObjArr[myindex].Istatus=0
      myindex = myNewObjArr.findIndex(x => x.serviceid === 128)
      myNewObjArr[myindex].Istatus=0

}else if(serviceid===133 && val===true){
    let myindex = myNewObjArr.findIndex(x => x.serviceid === 134)
    myNewObjArr[myindex].Istatus=0
    myindex = myNewObjArr.findIndex(x => x.serviceid === 133)
    myNewObjArr[myindex].Istatus=1

      // so wee need to find 127 and 128 and set them to 0
      myindex = myNewObjArr.findIndex(x => x.serviceid === 127)
      myNewObjArr[myindex].Istatus=0
      myindex = myNewObjArr.findIndex(x => x.serviceid === 128)
      myNewObjArr[myindex].Istatus=0

}else if(serviceid===134 && val===true){
    let myindex = myNewObjArr.findIndex(x => x.serviceid === 133)
    myNewObjArr[myindex].Istatus=0
    myindex = myNewObjArr.findIndex(x => x.serviceid === 134)
    myNewObjArr[myindex].Istatus=1

      // so wee need to find 127 and 128 and set them to 0
      myindex = myNewObjArr.findIndex(x => x.serviceid === 127)
      myNewObjArr[myindex].Istatus=0
      myindex = myNewObjArr.findIndex(x => x.serviceid === 128)
      myNewObjArr[myindex].Istatus=0

}else{
let myindex = myNewObjArr.findIndex(x => x.serviceid === serviceid)
myNewObjArr[myindex].Istatus=val
}
  // set the new object
  setServicesInformation(myNewObjArr)
  UpdateServiceStatus(serviceid,val)
}


async function UpdateServiceStatus(serviceid,val){

    let mynewObjarr=[...getServicesInformation]
    let myindex = mynewObjarr.findIndex(x => x.serviceid === serviceid)
    let ServiceName=mynewObjarr[myindex].benaemn

   setSpinnerLoading(true)
 //  console.log('update service status',serviceid,val)
    try{
        const response = await usePutData('updateUserPrescription',{serviceid,val})
        if(response.status===200){
           FetchUserDataUsage()
            setSpinnerLoading(false)
            setAlertCode(0)
            setAlertMessage(`${activateServiceMessage}${" "} ${ServiceName}`)
            setShowAlert(true)
           // navigate('/sig');
           buttonClickedHandler(6)
        }else if(response.status===201){
           FetchUserDataUsage()
            setSpinnerLoading(false)
            setAlertCode(0)
            setAlertMessage(`${deactivateServiceMessage} ${" "} ${ServiceName}`)
            setShowAlert(true)
        }
    }catch(error){
        setAlertCode(53013)
        setAlertMessage(selectedError[53013])
        setShowAlert(true)
        setSpinnerLoading(false)
        return;
    }
}



const handleRowClickIndex = (index,serviceid) => {// we get the index of the row and the serviceid

      setchosenServiceid(serviceid)
    if (expandedRow === index) { // here we check if the index is the same as the expanded row
        setExpandedRow(null);
        setSpinnerLoading(false)
      } else {

        setExpandedRow(index);
        setSpinnerLoading(true)
        setTimeout(() => {
            setSpinnerLoading(false)
        },200);
      }
    };

// async function CreateExcelFile(){        
//     console.log("create excel file")
//         setSpinnerLoading(true)
//         try{
//             const response = await useGetData('createExcelFile')
//             if(response.status===200){
//                 setAlertCode(0)
//                 setAlertMessage('Excel file has been created')
//                 setShowAlert(true)
//                 setSpinnerLoading(false)
//             }
//     }
//     catch(error){
//         setSpinnerLoading(false)
//         setAlertCode(53014)
//         setAlertMessage(selectedError[53014])
//         setShowAlert(true)
//         return;
//     }
//     }


async function FetchBillingData(fakturaid){
    setChosenInvoiceId(fakturaid)
    setSpinnerLoading(true)
    try{
        const response = await useGetData(`getBillingData/${fakturaid}`)
        console.log(response)
        if(response.status===200){
            setBillingData(response.data[0])
            setBillingTotalAmount(response.data[1])
            setSpinnerLoading(false)
          //  setAlertCode(0)
           // setAlertMessage('Billing data has been fetched')
            //setShowAlert(true)

        }

    
      }catch(error){
        setSpinnerLoading(false)
        setAlertCode(53015)
        setAlertMessage(selectedError[53015])
        setShowAlert(true)
    return;
}

}

const supportCategoryHandler=(val)=>{
    setSupportCategory(val)
}

const supportMessageHandler=(val)=>{

    setSupportMessage(val)
}

async function SendSupportMessageHandler(){
    setSpinnerLoading(true)
    try{
        const response = await usePostData('sendSupportMessage',{getSupportCategory,getSupportMessage})
        if(response.status===200){
            setSpinnerLoading(false)
            setAlertCode(0)
            setAlertMessage(supportMessageSendText1)
            setShowAlert(true)
            setSupportCategory(0)
            setSupportMessage('')
            FetchUserDataUsage()

        }
    }catch(error){
        setSpinnerLoading(false)
        setAlertCode(53016)
        setAlertMessage(selectedError[53016])
        setShowAlert(true)
    }
}

async function GetSupportTicketHandler(ticketid){
    setChosenTicketId(ticketid)
    
    setSpinnerLoading(true)
    try{
        const response = await useGetData(`getSupportTicket/${ticketid}`)
        if(response.status===200){
            setSupportTicket(response.data[0])
            setSpinnerLoading(false)
        }
    }catch(error){
        setSpinnerLoading(false)
        setAlertCode(53017)
        setAlertMessage(selectedError[53017])
        setShowAlert(true)
    }

}


async function DownloadPdfInvoice(fakturaid){
    setSpinnerLoading(true)
    try{
        const token = localStorage.getItem('auth_token');
        const headers = token ? { authorization: `Bearer ${token}` } : {};  
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/downloadPdfInvoice/${fakturaid}`,{ headers, responseType: 'blob' })
        if(response.status===200){
            const blob = new Blob([response.data], {type: 'application/pdf'});
            saveAs(blob, `Bakesoft_invoice_${fakturaid}.pdf`);

            setSpinnerLoading(false)
            setAlertCode(0)
            setAlertMessage(dowloadpdfOkMessage)
            setShowAlert(true)
        }
    }catch(error){
        setSpinnerLoading(false)
        setAlertCode(53019)
        setAlertMessage(selectedError[53019])
        setShowAlert(true)
    }
}

// async function CustomerPaymentHandler(userid,serviceid,transactionId){
//     console.log(transactionId)
//     setSpinnerLoading(true)
//     try{
//         const response = await usePostData('paypalPaymentInsideVerification',{userid,serviceid,transactionId})
//         if(response.status===200){
//             console.log(response)
//             FetchUserDataUsage()// renew user data
//             setSpinnerLoading(false)
//             setAlertCode(0)
//             setAlertMessage(paypalPaymentConfirmText)
//             setShowAlert(true)
        
//         }
//     }catch(error){
//         setSpinnerLoading(false)
//         setAlertCode(53020)
//         setAlertMessage(selectedError[53020])
//         setShowAlert(true)
//     }
// }

// const toggleMenu = () => {
//     setMenuOpen(!menuOpen);
// };

  // Function to fetch the Klarna client token from your backend
//   const fetchKlarnaPaymentSession = async (invoice,serviceId,serviceName,totalSum,chosenCurrency) => {
//     setChosenKlarnaInvoiceId(invoice)
//     setchosenKlarnaServiceid(serviceId)
//     setChosenKlarnaServiceName(serviceName)
//     setChosenKlarnaTotalSum(totalSum)
//     setChosenKlarnaCurrency(chosenCurrency)
//     setSpinnerLoading(true)

//     const totalSum1 = totalSum; // Example value in SEK
//     const taxRate = 2500; // 25% , klarna we use only for
//     const totalAmount = Math.round(totalSum1 * 100); // Total amount in minor units (e.g., cents)
//     const totalTaxAmount = Math.round((totalAmount * taxRate) / (10000 + taxRate)); // Correct tax amount calculation
    

//     try {
//         const token = localStorage.getItem('auth_token');
//       const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/klarnaPayment`, {
//         method: 'POST',
//         headers: {
//             'authorization': `Bearer ${token}`,
//           'Content-Type': 'application/json',
//         },
 
//         body: JSON.stringify({  
//             "purchase_country": getUserBillingAdressInfo[0].cc.toUpperCase(),
//             "purchase_currency":chosenCurrency.toUpperCase(),
//             "locale":getUserBillingAdressInfo[0].klarnaLocale,
//             "order_amount": totalAmount,
//             "order_tax_amount": totalTaxAmount,
//             "order_lines": [{
//                 "name" :serviceName,
//                 "quantity" :1,
//                 "unit_price" :totalAmount,
//                 "tax_rate" : taxRate,
//                 "total_amount" : totalAmount,
//                 "total_discount_amount" : 0,
//                 "total_tax_amount" : totalTaxAmount,
//                 "merchant_data":`{"invoice":${invoice},"serviceId":${serviceId}}`
//             },
//       ],
//         "billing_address": {
//             "given_name": getUserBillingAdressInfo[0].givenName,
//             "family_name": getUserBillingAdressInfo[0].familyName,
//             "email": getUserBillingAdressInfo[0].email,
//             "phone": getUserBillingAdressInfo[0].phone,
//             "street_address": getUserBillingAdressInfo[0].address,
//             "postal_code": getUserBillingAdressInfo[0].postalCode,
//             "city": getUserBillingAdressInfo[0].city,
//             "region": getUserBillingAdressInfo[0].region,
//             "country": getUserBillingAdressInfo[0].cc.toUpperCase(),
//         },

//       }),
//         });

//       if(response.status===200){
//       const data = await response.json();
//       setSpinnerLoading(false)
//       setClientToken(data.client_token);
//       setKlarna(true) // showing  the klarna button
//       }

//     } catch (error) {
//         setSpinnerLoading(false)
//         setAlertCode(53021)
//         setAlertMessage(selectedError[53021])
//         setShowAlert(true)

//     }
//   };

//   const finalizeKlarnaPayment = (authorizationToken,totalSum,totalTaxAmount) => {
//     let vatRate=Math.round(getUserBillingAdressInfo[0].vatRate * 100)
//     const token = localStorage.getItem('auth_token');
//     setSpinnerLoading(true)
    
//     fetch(`${process.env.REACT_APP_BACKEND_URL}/klarnaFinal`, {
//         method: 'POST',
//         headers: {
//             'authorization': `Bearer ${token}`,
//             'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//             service_id: getchosenKlarnaServiceid, // The service id the user pays for
//             invoice_id: getchosenKlarnaInvoiceId, // The invoice id the user pays for
//             country_code: getUserBillingAdressInfo[0].cc, // The country code of the user
//             authorization_token: authorizationToken, // The authorization token
//             purchase_country:getUserBillingAdressInfo[0].cc.toUpperCase(),
//             purchase_currency:getChosenKlarnaCurrency,
//             locale: getUserBillingAdressInfo[0].klarnaLocale,
//             order_amount: totalSum, // Total order amount in minor units (e.g., öre for SEK)
//             order_tax_amount: totalTaxAmount, // Total tax amount in minor units
//             order_lines: [
//                 {
//                     name: getChosenKlarnaServiceName, // Name of the service/item
//                     quantity: 1, // Quantity of the service/item
//                     unit_price: totalSum, // Unit price in minor units
//                     tax_rate: vatRate, // Tax rate in Klarna's expected format (e.g., 2500 for 25%)
//                     total_amount: totalSum, // Total amount for this item in minor units
//                     total_discount_amount: 0, // Any discounts applied to this item, in minor units
//                     total_tax_amount: totalTaxAmount, // Tax amount for this item in minor units
//                     merchant_data: `{"invoice":${getchosenKlarnaInvoiceId},"serviceId":${getchosenKlarnaServiceid}}` // Custom merchant data
//                 }
//             ],
//             billing_address: {
//                 given_name: getUserBillingAdressInfo[0].givenName, // The first name of the customer
//                 family_name: getUserBillingAdressInfo[0].familyName, // The last name of the customer
//                 email: getUserBillingAdressInfo[0].email, // The email address of the customer
//                 phone: getUserBillingAdressInfo[0].phone, // The phone number of the customer
//                 street_address: getUserBillingAdressInfo[0].address, // The street address of the customer
//                 street_address2:'',
//                 postal_code: getUserBillingAdressInfo[0].postalCode, // The postal code of the customer
//                 city:getUserBillingAdressInfo[0].city, // The city where the customer resides
//                 region: getUserBillingAdressInfo[0].region, // The region where the customer resides
//                 country:getUserBillingAdressInfo[0].cc.toUpperCase(), // The country where the customer resides
          


//             }
//         }), 
//     })
//     .then(response => response.json())
//     .then(data => {
//         if (data.success) {
//             FetchUserDataUsage()
//             setSpinnerLoading(false)
//             setAlertCode(0)
//             setAlertMessage(klarnaPaymentSuccessText)
//             setShowAlert(true)
//             setKlarna(false)

//          // console.log('Payment completed successfully');
//             // Handle success (e.g., show confirmation to user)
//         } else {
//             setSpinnerLoading(false)
//             setAlertCode(53022)
//             setAlertMessage(selectedError[53022])
//             setShowAlert(true)

//            // console.error('Payment completion failed', data);
//             // Handle error (e.g., show error message to user)
//         }
//     })
//     .catch(error => {
//         console.error('Error finalizing payment', error);
//     });
// };

const UpdatesupportMessageHandler=(val,ticketid)=>{
console.log('im here')
 let myNewObjArr=[...getSupportTicket]
    myNewObjArr[0].benaemn=val
    setSupportTicket(myNewObjArr)


}


//start here   and on 3250
async function UpdateClientSideSupportTicket(ticketid){
    setSpinnerLoading(true)
    try{
        const response = await usePostData('updateClientSideSupportTicket',{ticket:ticketid,getSupportTicket})
        if(response.status===200){
            setSpinnerLoading(false)
            setAlertCode(0)
            setAlertMessage(clientSideUpdateSupportTicketText)
            setShowAlert(true)
        }
    }catch(error){
        setSpinnerLoading(false)
        setAlertCode(53025)
        setAlertMessage(selectedError[53025])
        setShowAlert(true)
    }
}





async function GetInvoicesToPay(){
    setSpinnerLoading(true)
    try{
    let response=await useGetData(`getInvoicesToPay`)
    if(response.status===200){
        setSpinnerLoading(false)
        setInvoices(response.data[0])
    }else if(response.status===204){
        setSpinnerLoading(false)
        setAlertCode(0)
        setAlertMessage(noinvoicesFoundText)
        setShowAlert(true)
    }
}catch(error){
    setSpinnerLoading(false)
    setAlertCode(56025)
    setAlertMessage(selectedError[53027])
    setShowAlert(true)
}
}

const setChosenInvoiceHandler=(val)=>{
    setInfoStatus(true) // we show the invoice info panel
    if(val>0){
    let filteredInvoice=getInvoices.filter(data=>data.fakturaid==val)
    setInvoiceRows(filteredInvoice)
    let fakturaidNum=filteredInvoice[0].fakturaid
    let totalSumNum=filteredInvoice[0].totalsumLocalCurrVat
    let currencyShortNum=filteredInvoice[0].currencyShort
    let serviceIdNum=filteredInvoice[0].serviceid
    return GetPaymentIntent(fakturaidNum,totalSumNum,currencyShortNum,serviceIdNum)
    }else{
        return false;
    }

}


    // here we fetch the stripe payment intent
    async function GetPaymentIntent(fakturaidNum,totalSumNum,currencyShortNum,serviceIdNum){
        setSpinnerLoading(true)
        try{
            const  response = await usePostData('createPaymentIntentService',{fakturaidNum,totalSumNum,currencyShortNum,serviceIdNum})
            if(response.status===200){
                setSpinnerLoading(false)
                setClientSecret(response.data.clientSecret)
            }
            else if(response.status!==200){
                setSpinnerLoading(false)
                setAlertCode(0)
                setAlertMessage(paymentIntentErrorText)
                setShowAlert(true)
            }
        }catch(error){
            setSpinnerLoading(false)
            setAlertCode(53028)
            setAlertMessage(selectedError[53028])
            setShowAlert(true)
        }
 }
 async function updateUserStatusHandler(val) {
    // Ensure stripe instance
 return UpdateServiceStatusID()
}

   

  
  async function UpdateServiceStatusID() {
    setSpinnerLoading(true);
    try {
      // Send the payment intent ID to the backend for verification and update
      const response = await usePostData('UpdateServicesStatus', {
        payment_intent_id:clientSecret,
        service_id: getInvoiceRows[0].serviceid,
      });
  
      if (response.status === 200) {
        setSpinnerLoading(false);
        await FetchUserDataUsage()
        setIsPaymentComplete(true); // show success message component
        setInfoStatus(false)
      } else {
        setAlertCode(53029);
        setAlertMessage(selectedError[53029]);
        setShowAlert(true);

      }
    } catch (error) {
        setSpinnerLoading(false);
        setAlertCode(53029);
        setAlertMessage(selectedError[53029]);
        setShowAlert(true);
    }
  }
  
//   function HandleFileChange (e){
//     let selected = e.target.files[0];
  
//     if (selected) {
//       if (selected.size > maxSize) {
//         setImage(null);
//         setError('File too large. Maximum size is 3MB.');
//       } else if (!acceptedFileTypes.includes(selected.type)) {
//         setImage(null);
//         setError('Invalid file type. Only JPEG and PNG files are accepted.');
//       } else {
//         setImage(selected);
//         setError('');
//       }
//     }
//   }


const handleImageUpload = (event) => {
    const maxSize = 3 * 1024 * 1024; // 3MB
    const acceptedFileTypes = ['image/jpeg', 'image/png'];
    const file = event.target.files?.[0];
  
    if (!file) {
      setError('No file selected. Please select a file.');
      return;
    }
  
    // Validate file size
    if (file.size > maxSize) {
      setError('File too large. Maximum size is 3MB.');
      return;
    }
  
    // Validate file type
    if (!acceptedFileTypes.includes(file.type)) {
      setError('Invalid file type. Only JPEG and PNG files are accepted.');
      return;
    }
  
    setError(''); // Clear any previous errors
    setImage(file); // Store the raw file (buffer) for backend upload
  
    // Generate a base64 preview for display (optional)
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreview(reader.result); // Optional: Store base64 preview in a separate state
    };
    reader.onerror = () => {
      setError('Failed to read the file. Please try again.');
    };
    reader.readAsDataURL(file); // Convert for preview only

  };
  
    
async function UploadCreatorProfileImage() {
    console.log('upload creator profile')
    setSpinnerLoading(true);
  
    const formData = new FormData();
    formData.append('file',image); // Image file
    const token = localStorage.getItem('auth_token');
    const headers = token
      ? {
          Authorization: `Bearer ${token}`, // Include Authorization token
        }
      : {};
  
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/uploadCreatorProfileImage`, {
        method: 'POST',
        headers: headers,
        body: formData,
      });
  
      if (response.status === 200) {
        // Handle successful response
        setAlertCode(0);
        setAlertMessage('Profile and image uploaded successfully');
        setShowAlert(true);
      } else {
        // Handle failure response
        const errorData = await response.json();
        setAlertCode(1);
        setAlertMessage(errorData.error || 'Failed to upload profile');
        setShowAlert(true);
      }
    } catch (error) {
      console.error('Error uploading creator profile:', error);
      setAlertCode(53030);
      setAlertMessage('An error occurred while uploading');
      setShowAlert(true);
    } finally {
      setSpinnerLoading(false);
    }
  }
  //uploadLiveProfileInfo
console.log(profileText)

  async function UploadLiveProfileInfo() {
    setSpinnerLoading(true);

try{
    const response = await usePostData('uploadCreatorProfileInfo',{
        text:profileText,
        creatorAgreement:agreedToTerms,
        creatorPaypalEmail:paypalEmail
    })
    if(response.status===200){
        setSpinnerLoading(false)
       setAlertMessage('Profile information uploaded successfully')
        setShowAlert(true)
    }
}catch(error){
    setSpinnerLoading(false)
    setAlertCode(53030)
    setAlertMessage(selectedError[53030])
    setShowAlert(true)
}

  }
  const UploadLiveProfileInfoHandler=()=>{
    UploadCreatorProfileImage()
    UploadLiveProfileInfo()
    }




const PayComissionHandler=()=>{
    PayComission()
}

async function PayComission(){
    setSpinnerLoading(true)
    try{
        const response= await useGetData('payPalPayOutRequest')
        if(response.status===200){
            setSpinnerLoading(false)
            setAlertCode(0)
            setAlertMessage('Request has been made to send the money,confirmation is sent to your email')
            setShowAlert(true)
        }
    }catch(error){
        setSpinnerLoading(false)
        setAlertCode(53031)
        setAlertMessage(selectedError[53031])
        setShowAlert(true)
    }
}

const setCreatorPaypalEmailHandler=(val)=>{
    setCreatorPaypalEmail(val)
}

const handleCheckboxChange=()=>{
    setIsChecked(prevState=>!prevState)
}



  return (

<div>


<MyContext.Provider value={{
getName:getName||'',
getSurname:getSurname,
getAddress:getAddress,
getZipcode:getZipcode,
getCity:getCity,
getPhone:getPhone,
getEmail:getEmail,
getPassword:getPassword,
getPassword2:getPassword2,
getForetagName:getForetagName,
getUserDataUsage:getUserDataUsage,
getUserMainData:getUserMainData,
getUserInfo:getUserInfo,
getOldpass:getOldpass,
getNewpass:getNewpass,
getConfirmpass:getConfirmpass,
getUserAccountBalance:getUserAccountBalance,
getInvoiceNextDueDate:getInvoiceNextDueDate,
getUserInvoiceInfo:getUserInvoiceInfo,
getUserBillingAdressInfo:getUserBillingAdressInfo,
getTaxId:getTaxId,
getMaxValueSlider:getMaxValueSlider,
getMaxValue:getMaxValue,
getAccountInformation:getAccountInformation,
getServiceInformation:getServiceInformation,
getInvoiceRowInfo:getInvoiceRowInfo,
getServicePriceInformation:getServicePriceInformation,
getCurrencyInfo:getCurrencyInfo,
getServicesInformation:getServicesInformation,
getchosenServiceid:getchosenServiceid,
expandedRow:expandedRow,
getServicesSalesInformation:getServicesSalesInformation,
getMobileIsUsed:getMobileIsUsed,
getBillingData:getBillingData,
getOwningCompanyInfo:getOwningCompanyInfo,
websiteCompanyName:websiteCompanyName,
invoiceText:invoiceText,
broughtToYouText:broughtToYouText,
customerIdText:customerIdText,
serviceText:serviceText,
nameText:nameText,
numText:numText,
maxText:maxText,
debitText:debitText,
kreditText:kreditText,
totaltText:totaltText,
sumNetText:sumNetText,
sumVatText:sumVatText,
attBetalaText:attBetalaText,
bankgiroText:bankgiroText,
swishText:swishText,
termsText:termsText,
bankText:bankText,
bicText:bicText,
ibanText:ibanText,
getchosenInvoiceId:getchosenInvoiceId,
getCountry:getCountry,userFirstName,
userFamilyName:userFamilyName,
userCompany:userCompany,
userAdress:userAdress,
userPostalCode:userPostalCode,
userCity:userCity,
userRegion:userRegion,
userCountry:userCountry,
userPhone:userPhone,
userEmail:userEmail,
userSaveButtonText1:userSaveButtonText1,
userallServices:userallServices,
userService:userService,
userPrice:userPrice,
userActivate:userActivate,
numPageRequestsPerPage:numPageRequestsPerPage,
userPagenr:userPagenr,
userPageComponent:userPageComponent,
userPageNum:userPageNum,
userMyData:userMyData,
userMyDataDescriptionText:userMyDataDescriptionText,
userMyDataDescriptionText:userMyDataDescriptionText,
userMyData:userMyData,
userDataId:userDataId,
userDataName:userDataName,
userDataAntal:userDataAntal,
userDataId:userDataId,
userDataName:userDataName,
userDataAntal:userDataAntal,
userDataIngredientCategories:userDataIngredientCategories,
userDataRecipeCategories:userDataRecipeCategories,
userDataProductCategories:userDataProductCategories,
userDataIngredient:userDataIngredient,
userDataRecipes:userDataRecipes,
userDataProducts:userDataProducts,
userDataProductions:userDataProductions,
userDataRecipeImages:userDataRecipeImages,
userDataProductImages:userDataProductImages,
userDataInstructionText:userDataInstructionText,
useDataMyDataButtonText:useDataMyDataButtonText,
paymentPayPal:paymentPayPal,
paymentSwish:paymentSwish,
paymentCreditCard:paymentCreditCard,
paymentInvoice:paymentInvoice,

accountOverview:accountOverview,
userAccount:userAccount,
accountTransactionsFor:accountTransactionsFor,
accountService:accountService,
accountInvoice:accountInvoice,
accountDebit:accountDebit,
accountKredit:accountKredit,
accountDate:accountDate,
accountSystemComment:accountSystemComment,
accountTotalDebit:accountTotalDebit,
accountTotalKredit:accountTotalKredit,
accountTotalSaldo:accountTotalSaldo,
estimatedSaldo:estimatedSaldo,
estimatedSaldoText:estimatedSaldoText,
nextPaymenttext:nextPaymenttext,
prePaymentText:prePaymentText,
totalAmountUsed:totalAmountUsed,
invoiceHistoryText:invoiceHistoryText,
invoiceHistoryInvoice:invoiceHistoryInvoice,
invoiceHistorySum:invoiceHistorySum,
invoiceHistoryDate:invoiceHistoryDate,
invoiceHistoryStatus:invoiceHistoryStatus,
chooseInvoiceText:chooseInvoiceText,
invoiceRowsText:invoiceRowsText,
allInvoiceRowsText:allInvoiceRowsText,
invoiceRowsId:invoiceRowsId,
invoiceRowsService:invoiceRowsService,
invoiceRowsMax:invoiceRowsMax,
invoiceRowsRequest:invoiceRowsRequest,
invoiceRowsDebitQty:invoiceRowsDebitQty,
invoiceRowsPrice:invoiceRowsPrice,
invoiceRowsTotal:invoiceRowsTotal,
invoiceRowsType:invoiceRowsType,
invoiceRowsTotalSum:invoiceRowsTotalSum,

passwordValidText:passwordValidText,
newPasswordText:newPasswordText,
confirmPasswordText:confirmPasswordText,
showPasswordText:showPasswordText,
savePasswordButtonText:savePasswordButtonText,
iforgotMyPasswordText:iforgotMyPasswordText,
supportText:supportText,
settingsHeaderText:settingsHeaderText,
companyAndPersonalText:companyAndPersonalText,
addressInstructionText:addressInstructionText,
taxIdentificationVATText:taxIdentificationVATText,
taxIdInformationText:taxIdInformationText,
registerTaxIdButtonText:registerTaxIdButtonText,
maxValueWarningText:maxValueWarningText,
actiVateMaxValueText:actiVateMaxValueText,
maxValueButtonSaveText:maxValueButtonSaveText,
addressInfoButtontext:addressInfoButtontext,
servicesButtontext:servicesButtontext,
dataUsageButtontext:dataUsageButtontext,
mydataButtontext:mydataButtontext,
paymentButtontext:paymentButtontext,
myAccountButtonText:myAccountButtonText,
myInvoiceButtonText:myInvoiceButtonText,
passwordButtontext:passwordButtontext,
supportButtontext:supportButtontext,
settingsButtontext:settingsButtontext,
servicesButtonUsagetext:servicesButtonUsagetext,
getBillingData:getBillingData,
getBillingTotalAmount:getBillingTotalAmount,
userStat:userStat,
serviceHeaderText:serviceHeaderText,
servicePrisHeaderText:servicePrisHeaderText,
serviceActivateHeaderText:serviceActivateHeaderText,
serviceStatusHeaderText:serviceStatusHeaderText,
getUserTotalUsage:getUserTotalUsage,
debserviceidText:debserviceidText,
debserviceBenaemningText:debserviceBenaemningText,
debserviceQtyText:debserviceQtyText,
debserviceMaxText:debserviceMaxText,
debserviceUsableText:debserviceUsableText,
debserviceStatusText:debserviceStatusText,
getSupportCategories:getSupportCategories,
supportMText:supportMText,
supportMessageText:supportMessageText,
supportMessageSelectText:supportMessageSelectText,
supportMessageSendText:supportMessageSendText,
getSupportMessage:getSupportMessage,
getSupportTickets:getSupportTickets,
supportHistoryServiceIdText:supportHistoryServiceIdText,
supportHistoryDateText:supportHistoryDateText,
supportHistoryStatusText:supportHistoryStatusText,
getSupportTicket:getSupportTicket,
supportHistoryCategoryText:supportHistoryCategoryText,
messagesSentToSupportText:messagesSentToSupportText,
passwordHeaderText:passwordHeaderText,
resetPasswordHeaderText:resetPasswordHeaderText,
overviewText:overviewText,
overviewInformationText:overviewInformationText,
overviewInvoiceText:overviewInvoiceText,
invoiceSelectFirstOption:invoiceSelectFirstOption,
printInvoceButtonText:printInvoceButtonText,
getCountry:getCountry,
getMobileIsUsed:getMobileIsUsed,



InvoiceMaxVal:InvoiceMaxVal,
taxIdHandler:taxIdHandler,
setNameHandler:setNameHandler,
setSurNameHandler:setSurNameHandler,
setAddressHandler:setAddressHandler,
setZipCodeHandler:setZipCodeHandler,
setCityHandler:setCityHandler,
setPhoneHandler:setPhoneHandler,
setEmailHandler:setEmailHandler,
setPasswordHandler:setPasswordHandler,
setPassword2Handler:setPassword2Handler,
setForetagNameHandler:setForetagNameHandler,
SendUserDataByEmail:SendUserDataByEmail,
saveUserAddressInfoHandler:saveUserAddressInfoHandler,
setRegionHandler:setRegionHandler,
setCountryHandler:setCountryHandler,
savePrescriptionHandler:SavePrescriptionHandler,
consentHandler:consentHandler,
setNewPasswordHandler:setNewPasswordHandler,
saveNewPasswordHandler:SaveNewPassword,
sendPassByEmailToUser:SendPassByEmailToUser,
setMaxValue:setMaxValue,
toggleMaxValueSlider:toggleMaxValueSlider,

// ServicesAddHandler:ServicesAddHandler,
// CheckUserAccount:CheckUserAccount,
CheckIfBaseService:CheckIfBaseService,

handleRowClickIndex:handleRowClickIndex,
setSpinnerLoading:setSpinnerLoading,
FetchBillingData:FetchBillingData,
supportCategoryHandler:supportCategoryHandler,
supportMessageHandler:supportMessageHandler,
SendSupportMessageHandler:SendSupportMessageHandler,
GetSupportTicketHandler:GetSupportTicketHandler,
DownloadPdfInvoice:DownloadPdfInvoice,
getServiceInformation:getServiceInformation,
getCurrencyInfo:getCurrencyInfo,
getTotalCurrencyInfo:getTotalCurrencyInfo,

// paypal
paypalMainHeaderText:paypalMainHeaderText,
pptotalAmountToPayText:pptotalAmountToPayText,
ppChosenServiceText:ppChosenServiceText,
ppChosenCurrencyText:ppChosenCurrencyText,
registerAccountTabText:registerAccountTabText,
paymentTabText:paymentTabText,
ppBakesoftTermsText:ppBakesoftTermsText,
ppBakesoftTerms:ppBakesoftTerms,
paypalPaymentConfirmText:paypalPaymentConfirmText,
//CustomerPaymentHandler:CustomerPaymentHandler,
setAlertMessage:setAlertMessage,
setShowAlert:setShowAlert,
ppSelectInvoiceText:ppSelectInvoiceText,
ppSelectCurrencyText:ppSelectCurrencyText,
userDebitServices:userDebitServices,
myinformationText:myinformationText,
getUserMonthlyUsageCost:getUserMonthlyUsageCost,
getVatInfo:getVatInfo,
klarnaimage:klarnaimage,
// getKlarnaSnippet:getKlarnaSnippet,
//fetchKlarnaPaymentSession:fetchKlarnaPaymentSession,
clientToken:clientToken,
// loadKlarnaScript:loadKlarnaScript,
klarnaMainHeaderText:klarnaMainHeaderText,
paymentKlarna:paymentKlarna,
getKlarna:getKlarna,
setKlarna:setKlarna,
//fetchKlarnaPaymentSession:fetchKlarnaPaymentSession,
//finalizeKlarnaPayment:finalizeKlarnaPayment,
getChosenKlarnaServiceName:getChosenKlarnaServiceName,
getchosenKlarnaServiceid:getchosenKlarnaServiceid,
getChosenKlarnaTotalSum:getChosenKlarnaTotalSum,
paymentStatus:paymentStatus,
getCountry:getCountry,
klarnaPaymentfailureText:klarnaPaymentfailureText,
supportButtonAnswerText:supportButtonAnswerText,
UpdatesupportMessageHandler:UpdatesupportMessageHandler,
getChosenTicketId:getChosenTicketId,
UpdateClientSideSupportTicket:UpdateClientSideSupportTicket,
supportHistoryBenaemnText:supportHistoryBenaemnText,
paymentStandard:paymentStandard,
noinvoicesFoundText:noinvoicesFoundText,
selectedError:selectedError,
setAlertCode:setAlertCode,
setAlertMessage:setAlertMessage,
setShowAlert:setShowAlert,
getChosenInvoice:getChosenInvoice,
getInvoices:getInvoices,
getInvoiceRows:getInvoiceRows,
setChosenInvoiceHandler:setChosenInvoiceHandler,
clientSecret:clientSecret,
isPaymentComplete:isPaymentComplete,
stripePromise:stripePromise,
setIsPaymentComplete:setIsPaymentComplete,
isPaymentComplete:isPaymentComplete,
updateUserStatusHandler:updateUserStatusHandler,
getInfoStatus:getInfoStatus,
paymentSucceededText:paymentSucceededText,
getStatus:getStatus,
paymentFailedText:paymentFailedText,
thankYouPurchaseText:thankYouPurchaseText,
serviceActivatedText:serviceActivatedText,
paymentFailedMessageText2:paymentFailedMessageText2,
paymentFailedMessageText:paymentFailedMessageText,
isPayedText:isPayedText,
isNotPayedText:isNotPayedText,
//HandleFileChange:HandleFileChange,
choseImageButtontext:choseImageButtontext, 
error:error,
file:file,
//uploadImages:uploadImages,
choseImageUploadText:choseImageUploadText,
creatorImage:creatorImage,
creatorLivePresentation:creatorLivePresentation,
//profileTextHandler:profileTextHandler,
creatorProfileText:creatorProfileText,
liveAccountSetupText:liveAccountSetupText,
liveCreatorImageText:liveCreatorImageText,
liveCreatorPublicImageText:liveCreatorPublicImageText,
liveCreatorProfileText:liveCreatorProfileText,
liveMax500text:liveMax500text,
saveCreatorText:saveCreatorText,
userSalesTotalMonth:userSalesTotalMonth,
userTotalSales:userTotalSales,
userTotalSalesInMonth:userTotalSalesInMonth,
userKreditComission:userKreditComission,
bakesoftPayoutCharge:bakesoftPayoutCharge,
bakesoftKreditPrice:bakesoftKreditPrice,
PayComissionHandler:PayComissionHandler,
setCreatorPaypalEmailHandler:setCreatorPaypalEmailHandler,
//uploadPaypalEmailHandler:uploadPaypalEmailHandler,
creatorPaypalEmail:creatorPaypalEmail,
handleCheckboxChange:handleCheckboxChange,
isChecked:isChecked,
paypalEmailAcceptInfo:paypalEmailAcceptInfo,
step:step,
setStep:setStep,
image: image,
setImage:setImage,
profileText:profileText,
setProfileText:setProfileText,
paypalEmail:paypalEmail,
setPaypalEmail:setPaypalEmail,
agreedToTerms:agreedToTerms,
setAgreedToTerms:setAgreedToTerms,
isLoading:isLoading,
setIsLoading:setIsLoading,
handleImageUpload:handleImageUpload,
preview:preview,
UploadLiveProfileInfo:UploadLiveProfileInfo,
UploadCreatorProfileImage:UploadCreatorProfileImage,
UploadLiveProfileInfoHandler:UploadLiveProfileInfoHandler,









}}>



<div className="xxtotWrapper">    
 <div className={getBackGroundShade<1?"xxatotalArea":"xxatotalArea1"}>


 <div className="xxaTopArea">
        {getBackGroundShade<1?<img src={imageUrl} alt="Bakesoft" className="dabackgroundImageRecepyNew10" />:''}
</div>

         
             <div className="xxaheaderArea">
                <div className={getBackGroundShade<1?"xxacompName":"xxacompName1"}>{title}</div>
             </div>
            

   
        <div className="xxarangeArea">
             <input type="range" min="0.0" max="1" value={getMenuShade||0.2} step="0.10" id={2}
            title={'test'} className="xxarange2" onChange={(e)=>SetmenuColorHandler(e.target.value)}/>

             <input type="range" min="0.0" max="1" value={getBackGroundShade||0.2} step="0.10" id={2}
            title={'itest'} className="xxarange1" onChange={(e)=>SetBackGroundShadeHandler(e.target.value)}/>
          </div>
   



        <div className="areaA">
    
        
            <div className="menuTopWrapper">
            <div className="menuTop">
                <button className={getButtonClicked!==1?"menupersonal":"menupersonal1"} onClick={()=>buttonClickedHandler(1)}>{addressInfoButtontext}</button>
                <button className={getButtonClicked!==2?"menupersonal":"menupersonal1"} onClick={()=>buttonClickedHandler(2)}>{servicesButtontext}</button>
                <button className={getButtonClicked!==12?"menupersonal":"menupersonal1"} onClick={()=>buttonClickedHandler(12)}>{servicesButtonUsagetext}</button>
                <button className={getButtonClicked!==4?"menupersonal":"menupersonal1"} onClick={()=>buttonClickedHandler(4)}>{dataUsageButtontext}</button>
                <button className={getButtonClicked!==5?"menupersonal":"menupersonal1"} onClick={()=>buttonClickedHandler(5)}>{mydataButtontext}</button>
                <button className={getButtonClicked!==6?"menupersonal":"menupersonal1"} onClick={()=>buttonClickedHandler(6)}>{paymentButtontext}</button>
                <button className={getButtonClicked!==7?"menupersonal":"menupersonal1"} onClick={()=>buttonClickedHandler(7)}>{myAccountButtonText}</button>
                <button className={getButtonClicked!==8?"menupersonal":"menupersonal1"} onClick={()=>buttonClickedHandler(8)}>{myInvoiceButtonText}</button>
                <button className={getButtonClicked!==9?"menupersonal":"menupersonal1"} onClick={()=>buttonClickedHandler(9)}>{passwordButtontext}</button>
                <button className={getButtonClicked!==10?"menupersonal":"menupersonal1"} onClick={()=>buttonClickedHandler(10)}>{supportButtontext}</button>
                <button className={getButtonClicked!==13?"menupersonal":"menupersonal1"} onClick={()=>buttonClickedHandler(13)}>{liveAreaText}</button>
            {/* {!getMobileIsUsed&&(
                <button className={getButtonClicked!==14?"menupersonal":"menupersonal1"} onClick={()=>buttonClickedHandler(14)}>{liveAccountText}</button>
            )} */}


{/*              
                <button className={getButtonClicked!==11?"menupersonal":"menupersonal1"} onClick={()=>buttonClickedHandler(11)}>{settingsButtontext}</button> */}

            </div>
            </div>
    
        </div> 
        <div className="areaB">

{getButtonClicked===1 ? <Adressinfo/> : ''}
{getButtonClicked===2 ? <Tjanster/> : ''}
{/* {getButtonClicked===2 && getMobileIsUsed===false ? <Abonemang/> : ''} */}
{/* {getButtonClicked===2 && getMobileIsUsed===true ? <MobileAbonnemang/> : ''} */}
{getButtonClicked===3 ? <Tjanster/> : ''}
{getButtonClicked===4 ? <DataAnvandning/> : ''}
{getButtonClicked===5 ? <MinData/> : ''}
{getButtonClicked===6 ? <Betalning/> : ''}
{getButtonClicked===7 ? <KontoTop/> : ''}
{getButtonClicked===8 ? <MinaFakturor/> : ''}
{getButtonClicked===9 ? <Pass/> : ''}
{getButtonClicked===10? <SupportHeader/> : ''}
{getButtonClicked===11? <InvoiceSettings/> : ''}
{getButtonClicked===12? <TjansterDebitering/> : ''}
{getButtonClicked===15? <CallbackCompletePage/> : ''}
{getButtonClicked===16? <CallbackCompletePageFailed/> : ''}
{getButtonClicked===13? <LiveTabs/> : ''}
{/* {getButtonClicked===14? <LiveAccount/> : ''} */}

            </div>
    </div>
    </div>


{getSpinnerLoading && (
    <div className="spinnerContainer">
        <RotatingLines strokeColor="white" strokeWidth={getMobileIsUsed ? "2" : "2"} animationDuration="1" width="100" visible={true} />
    </div>
)}


  {getShowAlert===true?<Alert message={getAlertMessage} code={getAlertCode} onClose={handleCloseAlert}/>:''}

  </MyContext.Provider>

</div>


  )
}

function Adressinfo(){
const ctx = useContext(MyContext);

let givenName = ctx.getUserInfo && ctx.getUserInfo[0].givenName || ''
let familyName = ctx.getUserInfo && ctx.getUserInfo[0].familyName || ''
let company = ctx.getUserInfo && ctx.getUserInfo[0].company || ''
let address = ctx.getUserInfo && ctx.getUserInfo[0].address || ''
let postalCode = ctx.getUserInfo && ctx.getUserInfo[0].postalCode || ''
let city= ctx.getUserInfo && ctx.getUserInfo[0].city || ''
let region = ctx.getUserInfo && ctx.getUserInfo[0].region || ''
let phone= ctx.getUserInfo && ctx.getUserInfo[0].phone || ''
let email = ctx.getUserInfo && ctx.getUserInfo[0].email || ''
let country = ctx.getUserInfo && ctx.getUserInfo[0].country || ''


    return(
        <>
        <div className="xxaadressinfoHeaderWrapper">
  
    </div>
    <div className="xxaInfoTop">
         <div className="xxaInfoWrapper">
            <div className="xxainfoSurround">
    <div className="adressinfoTop">
    <div className="xxaadressinfoHeaderText">{ctx.myinformationText}</div>

            <div className="xxaInfoRow">
                  <div className="xxaInfoRowWrapper">
                       <div className="xxaInfoType">{ctx.userFirstName}:</div>
                             <div className="xxaInputWrapper">
                                  <input type="text" id="namn" className="xxaInfoInput" autoComplete=''
                                    onChange={(e) => ctx.setNameHandler(e.target.value)}
                                    value={givenName || ''} /> 
                            </div>
                      </div>
                </div>
           

            <div className="xxaInfoRow">
                  <div className="xxaInfoRowWrapper">
                       <div className="xxaInfoType">{ctx.userFamilyName}:</div>
                             <div className="xxaInputWrapper">
                                  <input type="text" id="namn" className="xxaInfoInput" autoComplete=''
                                    onChange={(e) => ctx.setSurNameHandler(e.target.value)}
                                    value={familyName || ''} /> 
                            </div>
                </div>
            </div>

            <div className="xxaInfoRow">
                  <div className="xxaInfoRowWrapper">
                       <div className="xxaInfoType">{ctx.userCompany}:</div>
                             <div className="xxaInputWrapper">
                                  <input type="text" id="namn" className="xxaInfoInput" autoComplete=''
                                    onChange={(e) => ctx.setForetagNameHandler(e.target.value)}
                                    value={company || ''} /> 
                            </div>
                </div>
            </div>

            <div className="xxaInfoRow">
                  <div className="xxaInfoRowWrapper">
                       <div className="xxaInfoType">{ctx.userAdress}:</div>
                             <div className="xxaInputWrapper">
                                  <input type="text" id="namn" className="xxaInfoInput" autoComplete=''
                                    onChange={(e) => ctx.setAddressHandler(e.target.value)}
                                    value={address || ''} /> 
                            </div>
                </div>
            </div>

            <div className="xxaInfoRow">
                  <div className="xxaInfoRowWrapper">
                       <div className="xxaInfoType">{ctx.userPostalCode}:</div>
                             <div className="xxaInputWrapper">
                                  <input type="text" id="namn" className="xxaInfoInput" autoComplete=''
                                    onChange={(e) => ctx.setZipCodeHandler(e.target.value)}
                                    value={postalCode || ''} /> 
                            </div>
                </div>
            </div>

            <div className="xxaInfoRow">
                  <div className="xxaInfoRowWrapper">
                       <div className="xxaInfoType">{ctx.userCity}:</div>
                             <div className="xxaInputWrapper">
                                  <input type="text" id="namn" className="xxaInfoInput" autoComplete=''
                                    onChange={(e) => ctx.setCityHandler(e.target.value)}
                                    value={city || ''} /> 
                            </div>
                </div>
            </div>

            <div className="xxaInfoRow">
                  <div className="xxaInfoRowWrapper">
                       <div className="xxaInfoType">{ctx.userRegion}:</div>
                             <div className="xxaInputWrapper">
                                  <input type="text" id="namn" className="xxaInfoInput" autoComplete=''
                                    onChange={(e) => ctx.setRegionHandler(e.target.value)}
                                    value={region || ''} /> 
                            </div>
                </div>
            </div>

            <div className="xxaInfoRow">
                  <div className="xxaInfoRowWrapper">
                       <div className="xxaInfoType">{ctx.userCountry}:</div>
                             <div className="xxaInputWrapper">
                                  <input type="text" id="namn" className="xxaInfoInput" autoComplete=''
                                    onChange={(e) => ctx.setCountryHandler(e.target.value)}
                                    value={country || ''} /> 
                            </div>
                </div>
            </div>

            
            <div className="xxaInfoRow">
                  <div className="xxaInfoRowWrapper">
                       <div className="xxaInfoType">{ctx.userPhone}:</div>
                             <div className="xxaInputWrapper">
                                  <input type="text" id="namn" className="xxaInfoInput" autoComplete=''
                                    onChange={(e) => ctx.setPhoneHandler(e.target.value)}
                                    value={phone || ''} /> 
                            </div>
                </div>
            </div>

            <div className="xxaInfoRow">
                  <div className="xxaInfoRowWrapper">
                       <div className="xxaInfoType">{ctx.userEmail}:</div>
                             <div className="xxaInputWrapper">
                                  <input type="text" id="namn" className="xxaInfoInput1" autoComplete=''
                                    value={email || ''} 
                                    readOnly
                                    /> 
                            </div>
                </div>
            </div>
        
        
<div className="xxaInfoRow">
                <button className="saveAddressInfoButton"
                onClick={ctx.saveUserAddressInfoHandler}
                >{ctx.userSaveButtonText1}</button>
    </div>

                </div>
            </div>
</div>
</div>
</>

    )
}



function Tjanster(){
    const ctx = useContext(MyContext);
    let vatRate=ctx.getVatInfo[0].vatRate

     // here we will account with currency xchange
     let currencySymbol=ctx.getCurrencyInfo[0].currencySymbol
     let xchangeRate=ctx.getCurrencyInfo[0].xchangeRate
     let salesInfo=ctx.getServicesSalesInformation

     function classPicker(istatus){
        if(istatus===null){
            return("slider2 round")
        }else if(istatus===1){
            return("slider1 round")
        }else if(istatus==0){
            return("slider2 round")
        }else if(istatus===2){
            return("slider2 round")
        }else if(istatus===3){
            return("slider2 round")
        }
     }

     function checkedPicker(istatus){
        if(istatus===null){
            return(false)
        }else if(istatus===1){
            return(true)
        }else if(istatus==0){
            return(false)
        }else if(istatus===2){
            return(true)
        }else if(istatus===3){
            return(false)
        }
     }



            return(
    <div className="tjansterTop">
         <div className="tjansterHeaderWrapper">
                <div className="tjansterHeaderText">{ctx.userallServices}</div>
         </div>
       
            <div className="tjansterWrapper">
            {ctx.getServicesInformation.map((data,index)=>(
                <div key={index} className="tjansterRow">

                       {index>0?
                    <div className="tjansterId" 
                    onClick={()=>ctx.handleRowClickIndex(index,data.serviceid)}
                    >{data.serviceid}</div>
                    :<div className="tjansterId1"></div>}

                    

                     {index>0?
                    <div className="tjansterBenaemn" title='Klicka för att se mer information'
                    onClick={()=>ctx.handleRowClickIndex(index,data.serviceid)}
                    >{data.benaemn}</div>
                    :<div className="tjansterBenaemn1">{ctx.serviceHeaderText}</div>}


                    {index>0?
                    <div className="tjansterPris">{currencySymbol}{' '}

                    {(data.price/xchangeRate < 10) ? ((data.price / xchangeRate) * (1 + vatRate/100)).toFixed(2): Math.floor((data.price / xchangeRate) * (1 + vatRate/100))} /{data.quoteText}
                  </div>
                  :<div className="tjansterPris1">{ctx.servicePrisHeaderText}</div>}



                 {index===0?(
                    <div className="tjansterAdd1">{ctx.serviceActivateHeaderText}</div>
                 ):(
                  index>0 && data.prescriptionControl === 1 && (
                 
                  <div className="tjansterAdd">
                            <label className="switch1">
                                <input type="checkbox"  onChange={(e) => ctx.CheckIfBaseService(e.target.checked, data.serviceid)} checked={checkedPicker(data.Istatus)} />
                                <span className={classPicker(data.Istatus)}></span>
                           
                            </label>
                        </div>
                  )
                    )}

                   {index>0? 
                   <div className="tjansterStat">{data.sysbenaemn}</div>
                   :<div className="tjansterStat1">{ctx.serviceStatusHeaderText}</div>}
                   
                 {ctx.expandedRow === index && (
                        <div className="tjansteranimated">
                            <div className="tjansteranimatedText">
                                {salesInfo.filter(data => data.serviceid === ctx.getchosenServiceid).map((data, index) => (
                                <div key={index} className="tjansteranimatedRow">
                                    <div className="tjansteranimatedBenaemn"><li>{data.benaemn}</li></div>

                               </div>

                                ))}

                            </div>
                    
                </div>)}
              
              </div>
            ))}
         
        </div>
    </div>
   



    )

}


function DataAnvandning(){
    const ctx = useContext(MyContext);
    return(
    <div className="daAnvandningTop">
        <div className="daAnvandningHeader">
            <div className="daAnvandningText">{ctx.numPageRequestsPerPage}</div>
        </div>
{/* 
      <div className="daheaderTopWrapper">
        <div className="daHeaderTop">
            <div className="daHeaderTrackid">{ctx.userPagenr}</div>
            <div className="daHeaderBenaemn">{ctx.userPageComponent}</div>
            <div className="daHeaderAntal">{ctx.userPageNum}</div>
        </div>
        </div>
         <div className='ddrowTopWrapper'>
        <div className="ddRowTop">
            {ctx.getUserDataUsage.map((data,index)=>(
             <div key={index} className="ddRow">
            <div className="daRowTrackid">{data.trackid}</div>
            <div className="daRowBenaemn">{data.benaemn}</div>
            <div className="daRowAntal">{data.antal}</div>
            </div>
            ))}
        </div>
        </div> */}
<div className='ddrowTopWrapper'>
    <div className="ddRowTop">
        {ctx.getUserDataUsage.map((data, index) => (
            <div key={index} className="ddRow">
                {index === 0 && (
                 
                            <><div className="daRowTrackid">{ctx.userPagenr}</div>
                            <div className="daRowBenaemn">{ctx.userPageComponent}</div>
                            <div className="daRowAntal">{ctx.userPageNum}</div></>
              
                )}
                {index > 0 && (
                    <>
                        <div className="daRowTrackid">{data.trackid}</div>
                        <div className="daRowBenaemn">{data.benaemn}</div>
                        <div className="daRowAntal">{data.antal}</div>
                    </>
                )}
            </div>
        ))}
    </div>
</div>



    </div>





    )
}


function MinData(){
    const ctx = useContext(MyContext);

    return(

<div className="minDataTop">
    <div className="minDataHeaderTextWrapper">
        <div className="minDataHeaderText">{ctx.userMyData}</div>
    </div>
    <div className="minaDataInformation">{ctx.userMyDataDescriptionText}</div>
    <div className="minaDataHeaderTopWrapper">
        <div className="minaDataHeaderTop header">
            <div className="minaDataHeaderServicesId1">{ctx.userDataId}</div>
            <div className="minaDataHeaderServicesBenaemn1">{ctx.userDataName}</div>
            <div className="minaDataHeaderServicesAntal1">{ctx.userDataAntal}</div>
        </div>
        {ctx.getUserMainData.map((data, index) => (
            <div key={index} className="minaDataHeaderTop listItem">
                <div className="minaDataHeaderServicesId">{index + 1}</div>
                <div className="minaDataHeaderServicesBenaemn">{data.category}</div>
                <div className="minaDataHeaderServicesAntal">{data.num}</div>
            </div>
        ))}
    </div>
    <div className="minaDataSendInfo">
        <div className="minaDataSendInfoText">{ctx.userDataInstructionText}</div>
        <button onClick={ctx.SendUserDataByEmail} className="minaDataSendInfoButton">
            {ctx.useDataMyDataButtonText}
        </button>
    </div>
</div>




    )
}

function Betalning(){
    const ctx = useContext(MyContext);
    const[getInvoiceTab,setInvoiceTab]=useState(1)
 //   ctx.setKlarna(false)
    return(
        
        <div className="psinvoiceTop">
        <div className="psInvoiceTabTop">

        <button className={`psinvoiceTab psinvoicefirstButton ${getInvoiceTab===1?'active':''}`}
        onClick={()=>setInvoiceTab(1)}>{ctx.paymentStandard}</button>

        
        {/* <button className={`mobileTab ${getInvoiceTab===2?'active':''}`}
        // show klarna only to swedish users
        onClick={()=>setInvoiceTab(2)}>{ctx.paymentStandard}</button>
     */}


        <button className={`psinvoiceTab psinvoicelastButton ${getInvoiceTab===4?'active':''}`}
        onClick={()=>setInvoiceTab(4)}>{ctx.paymentSwish}</button>

    </div>

        <div className="psinvoiceTabContent">
        {getInvoiceTab===1 ? <GeneralPayment/> : ''}
        {getInvoiceTab===4 ? <BetalningFaktura/> : ''}
        </div></div>
    )
}

function GeneralPayment(){
    const ctx = useContext(MyContext);
   let  clientSecret=ctx.clientSecret

   

   async function handlePaymentSuccess(){
     ctx.updateUserStatusHandler()
   };

    return(
        <div className="generalPaymentTop">
            <div className="generalPaymentHeaderWrapper">
                <div className="generalPaymentHeaderText">Väntande betalningar</div>
            </div>

        <div className="generalPaymentBoxTopWrapper">
            <div className="generalPaymentBoxTop">
                <select className="generalPaymentSelect" onChange={(e)=>ctx.setChosenInvoiceHandler(e.target.value)}>
                    <option value="">{ctx.ppSelectInvoiceText}</option>
                    {ctx.getInvoices.map((data,index)=>(
                        <option key={index} value={data.fakturaid}>{data.fakturaid}{' - '}{data.benaemn}</option>
                    ))}
                </select>
                {ctx.getInfoStatus && (
                    <div className="generalPaymentInfo">
        <div className="generalPaymentInfoBoxWrapper">
            <div className="generalPaymentInfoBox">
            
                <div className="generalPaymentInfoText">
                    {ctx.pptotalAmountToPayText}:{ctx.getInvoiceRows&&ctx.getInvoiceRows[0].totalsumLocalCurrVat}</div>
                <div className="generalPaymentInfoText">{ctx.ppChosenServiceText}:{ctx.getInvoiceRows&&ctx.getInvoiceRows[0].benaemn}</div>
                <div className="generalPaymentInfoText">{ctx.ppChosenCurrencyText}:{ctx.getInvoiceRows&&ctx.getInvoiceRows[0].currencyShort}</div>
            </div>
        
         
         </div>
        </div>
           )}


            </div>
        </div>
        {ctx.clientSecret && (
                    <Elements stripe={ctx.stripePromise} options={{clientSecret, appearance:appearance }}>
                        {ctx.isPaymentComplete 
                            ? <CompletePage clientSecret={ctx.clientSecret} /> 
                            : <CheckoutForm onPaymentSuccess={handlePaymentSuccess} clientSecret={ctx.clientSecret} />
                        }
                    </Elements>
                )}
    
        </div>
    )

}

function CheckoutForm({ onPaymentSuccess, clientSecret }) {
    let ctx = useContext(MyContext)
    const stripe = useStripe();
    const elements = useElements();
  
    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
  
    const handleSubmit = async (e) => {
        ctx.setSpinnerLoading(true)
      e.preventDefault();
  
      if (!stripe || !elements) {
        // Stripe.js hasn't yet loaded.
        return;
      }
  
      setIsLoading(true);
  
      // Confirm payment using the PaymentElement
      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          // Change to your actual completion page
          return_url: "https://www.bakesoft.se/returncall2?buttonid=22",
        },
        redirect: 'if_required', // Redirect to the URL if no additional authentication is required
      });
  
      // Handle any errors returned during confirmation
      if (error) {
        if (error.type === "card_error" || error.type === "validation_error") {
          setMessage(error.message);
        } else {
          setMessage("An unexpected error occurred.");
        }
        setIsLoading(false);
      } else {
       
        // Trigger success callback
        onPaymentSuccess(); // Ensure this callback is executed correctly
      }
    };
  
    const paymentElementOptions = {
      layout: "tabs", // Allow users to switch between payment methods
    };
  
    return (
      <>
      <div className="generalPaymentFormTopWrapper">
        <form id="payment-form" onSubmit={handleSubmit}>
          <PaymentElement id="payment-element" options={paymentElementOptions} />
          <button disabled={isLoading || !stripe || !elements} id="submit" className="infoPayFormButton">
            <span id="button-text">
              {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
            </span>
          </button>
          {/* Display any error messages */}
          {message && <div id="payment-message">{message}</div>}
        </form>
  
        {/* DEV: Show dynamic payment methods annotation and checker */}
        <div id="dpm-annotation">
    
        </div>
    </div>
      </>
    );
}

    function CompletePage({ clientSecret }) {
        let ctx = useContext(MyContext)
      const stripe = useStripe();
      const[getIntentId,setIntentId]=useState('')
     const[getStatus,setStatus]=useState('')
      // we delete this token since its just used for signing up
      localStorage.removeItem('contwithauth_token')
      

      
      
      
    //   const STATUS_CONTENT_MAP = {
      
    //     succeeded: {
    //       text:ctx.paymentSucceededText||'Payment succeeded',
    //       sentEmailText:ctx.paymentSucceededEmailText||'We have sent you an email with information!',
    //       iconColor: "#30B130",
    //       icon: SuccessIcon,
    //       emailIcon: EmailIcon,
    //     },
    //     processing: {
    //       text:ctx.paymentProcessingText||'Payment processing',
    //       sentEmailText:'',
    //       iconColor: "#6D6E78",
    //       icon: InfoIcon,
    //     },
    //     requires_payment_method: {
    //       text:ctx.paymentFailedTextInfo||'Payment failed, try again',
    //       sentEmailText:'',
    //       iconColor: "#DF1B41",
    //       icon: ErrorIcon,
    //     },
    //     default: {
    //       text:ctx.paymentSomethingWentWrongText||'Something went wrong,try again',
    //       sentEmailText:'',
    //       iconColor: "#DF1B41",
    //       icon: ErrorIcon,
    //     }
    //     };
      
      useEffect(() => {
        if (!stripe || !clientSecret) {
          return;
        }
         setStatus('')
        stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
          if (!paymentIntent) {
            return;
          }
      
          setStatus(paymentIntent.status);
          setIntentId(paymentIntent.id); 
          ctx.setSpinnerLoading(false)
        });
      }, [stripe, clientSecret]);
    
      
      return (
        <div className="completePageWrapper">
      {getStatus==='succeeded' &&(
        <div className="infocompleteWrapper">
               <CheckCircle2 className="completePageIcon"/>
            
        {ctx.paymentSucceededText}
        <div className="infoCompleteContinueWrapper">
            <div className="infoCompleteContinueHeader">{ctx.serviceActivatedText}</div>
            <div className="infoCompleteContinueText">{ctx.thankYouPurchaseText}</div>

        </div>
        </div>
      )}
        {!getStatus==='succeeded' &&(
            <div className="infocompleteWrapper">
                 <XCircle className="failPageIcon"/>
                
            {ctx.paymentProcessingText}
            </div>
        )}



      </div>
      
    )
}

function CallbackCompletePage(){
    let ctx = useContext(MyContext)
 

    return(
        <div className="cbcompletePageWrapper">
          <div className="infocompleteWrapper">
          <CheckCircle2 className="completePageIcon"/>
       
   {ctx.paymentSucceededText}
   <div className="infoCompleteContinueWrapper">
       <div className="infoCompleteContinueHeader">{ctx.serviceActivatedText}</div>
       <div className="infoCompleteContinueText">{ctx.thankYouPurchaseText}</div>

   </div>
   </div>

        </div>
    )
}

function CallbackCompletePageFailed(){
    let ctx = useContext(MyContext)

    return(
        <div className="cbcompletePageWrapper">

          <div className="infocompleteWrapper">
             <XCircle className="failPageIcon"/>
       
   {ctx.paymentFailedMessageText}
   <div className="infoCompleteContinueWrapper">
       <div className="infoCompleteContinueText">{ctx.paymentFailedMessageText2}</div>

   </div>
   </div>
        </div>
    )
}




function BetalningFaktura(){
    const ctx = useContext(MyContext);
    return(
        <div>{ctx.paymentInvoice}</div>
    )
}
function KontoTop(){
    const ctx = useContext(MyContext);
    const[getInvoiceTab,setInvoiceTab]=useState(1)
    return(
        <div className="psinvoiceTop">
        <div className="psInvoiceTabTop">
        <button className={`psinvoiceTab psinvoicefirstButton ${getInvoiceTab===1?'active':''}`}
        onClick={()=>setInvoiceTab(1)}>{ctx.accountOverview}</button>
        <button className={`mobileTab ${getInvoiceTab===2?'active':''}`}
        onClick={()=>setInvoiceTab(2)}>{ctx.userAccount}</button>


    </div>

        <div className="psinvoiceTabContent">
        {getInvoiceTab===1 ? <InvoiceOverView/> : ''}
        {getInvoiceTab===2 ? <Konto/> : ''}


        </div></div>
    )

}

function Konto(){
    const ctx = useContext(MyContext);
    const[getId,setId]=useState('')

    // here we will account with currency xchange
    let currencySymbol=ctx.getCurrencyInfo[0].currencySymbol
    let xchangeRate=ctx.getCurrencyInfo[0].xchangeRate

    let fullremark = ctx.getAccountInformation.find(service => service.serviceid === getId)?.remark || '';
    let serviceName = ctx.getServiceInformation.find(service => service.serviceid === getId)?.benaemn || '';
    let userNameInfo = ctx.getUserBillingAdressInfo[0].givenName||''+' '+ctx.getUserBillingAdressInfo[0].familyName||''
    let userTotKredit=ctx.getUserAccountBalance[0].kreditsum
    let userTotKreditInLocalCurrency=userTotKredit/xchangeRate
    let serviceBalance=ctx.getUserAccountBalance[0].netbalance   // debit - kredit
    let userPaymentBalance=ctx.getUserAccountBalance[0].userBalance  // userdebit-userkredit
    let saldo=serviceBalance-userPaymentBalance   // debit-kredit-userdebit/userkredit
       saldo = saldo/xchangeRate
    let rowInfo=ctx.getInvoiceRowInfo

    return(
        <div className="kontoTop">
            <div className="kontoHeaderWrapper">
            <div className="kontoHeader">{ctx.userAccount}</div>
            </div>
            <div className="kontoInfoTop">
            <div className="kontoInfoWrapper">
                 {/* // make achange here to reverse the order , set name first then the words
                 // */}
               {ctx.getCountry!=='ja'?
                <div className="kontoInfoHeader">{ctx.accountTransactionsFor}{' '} {userNameInfo} </div>
                :<div className="kontoInfoHeader">{userNameInfo}{' '}{ctx.accountTransactionsFor}</div>}
               
               
                {/* // here will will make a header, for the user to see the information about the account
                serviceid,fakturaid,debit,kredit,createDate,remark */}
                <div className="kontoInfoHeaderWrapper">
                    <div className="kontoInfoService">{ctx.accountService}</div>
                    <div className="kontoInfoFaktura">{ctx.accountInvoice}</div>
                    <div className="kontoInfoDebit">{ctx. accountDebit}</div>
                    <div className="kontoInfoDatum">{ctx.accountDate}</div>
                    <div className="kontoInfoKommentar">{ctx.ccountSystemComment}</div>
                </div>
                <div className="kontoInfoListWrapper">
                    {ctx.getAccountInformation.map((data,index)=>(
                        <div key={index} className="kontoInfoList">
                            <div className="kontolistService"
                            onClick={()=>setId(data.serviceid)}
                            >{data.serviceid}</div>
                            <div className="kontolistfaktura">{data.fakturaid}</div>
                            <div className="kontolistdebit">{(data.debit/xchangeRate).toFixed(2)}</div>
                            <div className="kontolistdate">{String(data.createDate).substring(5,10)}</div>
                            {ctx.getMobileIsUsed===true?
                            <div className="kontolistkommentar">{String(data.remark).substring(0,15)}</div>
                            :<div className="kontolistkommentar">{data.remark}</div>}
                        </div>

                    ))}

                  <div className="footerHeader">
    
                    <div className="footerHeaderSaldo">{ctx.accountTotalSaldo}</div>
                    </div>
                    <div className="footerHeaderList">

                        <div className="footerHeaderSaldo">{saldo.toFixed(2)}</div>


                        </div>
            </div>
            </div>
            <div className="kontoInfoServiceName">{serviceName}</div>
            <div className="kontoInfoServiceName">{fullremark}</div>
        </div>










        </div>
    )

}


function MinaFakturor(){
    const ctx = useContext(MyContext);
    const[getInvoiceTab,setInvoiceTab]=useState(2)
 return(
<div className="psinvoiceTop">
    <div className="psInvoiceTabTop">

        <button className={`mobileTab ${getInvoiceTab===2?'active':''}`}
        onClick={()=>setInvoiceTab(2)}>{ctx.overviewText}</button>

               <button className={`psinvoiceTab psinvoicelastButton ${getInvoiceTab===3?'active':''}`}
        onClick={()=>setInvoiceTab(3)}>{ctx.overviewInformationText}</button>

{/* <button className={`psinvoiceTab psinvoicelastButton ${getInvoiceTab===1?'active':''}`}
        onClick={()=>setInvoiceTab(1)}>{ctx.overviewInvoiceText}</button> */}




    </div>

    <div className="psinvoiceTabContent">

        {/* {getInvoiceTab===1 ? <Faktura/> : ''} */}
        {getInvoiceTab===2 ? <InvoiceHistory/> : ''}
        {getInvoiceTab===3 ? <InvoiceRows/> : ''}

        </div>
</div>

 )
}





function InvoiceOverView(){
    const ctx = useContext(MyContext);
    let netSum=ctx.getUserAccountBalance && ctx.getUserAccountBalance[0].netbalance || 0  // debit-kredit

    let nextDueDate = (ctx.getInvoiceNextDueDate[0].dueDate||'Date failed to load').substring(0,10)
    let userBalance=ctx.getUserAccountBalance && ctx.getUserAccountBalance[0].userBalance || 0  //userdebit-userkredit
    // here we will account with currency xchange
    let currencySymbol=ctx.getCurrencyInfo[0].currencySymbol
    let xchangeRate=ctx.getCurrencyInfo[0].xchangeRate
    //net totalsum
    let netsumInLocalCurrency=netSum-userBalance // DEBIT-KREDIT-USERDEBIT/USERKREDIT
    netsumInLocalCurrency=netsumInLocalCurrency/xchangeRate
    // vatRate
    let vatRate=ctx.getVatInfo[0].vatRate
    // user monthly net cost
    let userMonthlyNetCost=ctx.getUserMonthlyUsageCost && ctx.getUserMonthlyUsageCost.length>0 && ctx.getUserMonthlyUsageCost[0].netsum || 0

   // let userMontlyNetCostInLocalCurrency=userMonthlyNetCost/xchangeRate
    let userMontlyNetCostInLocalCurrency = (userMonthlyNetCost / xchangeRate) * (1 + (vatRate / 100));

    return(
        <div className="psinvoiceOverviewTop">
            <div className="psinvoiceOverviewHeaderWrapper">
            <div className="psinvoiceOverviewHeader">{ctx.estimatedSaldo}
            </div>
            <div className="psinvoiceOverviewSum">{currencySymbol}&nbsp;{Math.round(userMontlyNetCostInLocalCurrency)}</div>
            <div className="psexplanationTextWrapper">
            <div className="psexplanationText">{ctx.estimatedSaldoText}</div>
            </div>
            </div>

            <div className="pspaymentChoicesWrapper">
                <div className="pspaymentNextPaymentDue">{ctx.nextPaymenttext}</div>
                <div className="pspaymentNextPaymentDueDate">{nextDueDate}</div>
                {/* <div className="psline"></div>
                <div className="pstotalyUsed">{ctx.totalAmountUsed}</div>
                <div className="pstotalyUsedSum">{currencySymbol}&nbsp;{Math.round(debitSumInLocalCurrency)}</div> */}


            </div>

        </div>
    )
}

function InvoiceHistory(){
    const ctx = useContext(MyContext);
     let userInvoiceInfo = ctx.getUserInvoiceInfo
         // here we will account with currency xchange
    let xchangeRate=ctx.getCurrencyInfo[0].xchangeRate

    //getUserInvoiceInfo
    return(
        <div className="invoiceHistoryTop">
            <div className="invoiceHistoryHeaderWrapper">
                {ctx.getMobileIsUsed!==true?
            <div className="invoiceHistoryHeader">{ctx.invoiceHistoryText}</div>:''}
            </div>
            <div className="invoiceHeaderTop">
            <div className="invoicelistWrapper">
                <div className="invoiceHistoryHeader1">{ctx.invoiceHistoryInvoice}</div>
                <div className="invoiceHistoryHeader1">{ctx.invoiceHistorySum}</div>
                <div className="invoiceHistoryHeader1">{ctx. invoiceHistoryDate}</div>
                <div className="invoiceHistoryHeader1">PDF</div>
                <div className="invoiceHistoryHeader1">Receipt</div>
                <div className="invoiceHistoryHeader1">Betala</div>




            </div></div>
            <div className="invoiceHistoryListWrapper">
                {userInvoiceInfo.map((data,index)=>(
                    <div key={index} className="invoiceHistoryList">
                        <div className="invoiceHistoryListData">{data.fakturaid}</div>
                        <div className="invoiceHistoryListData">{Math.round(data.nettotal/xchangeRate)}</div>
                        <div className="invoiceHistoryListData">{String(data.createDate).substring(2,10)}</div>
                        <div className="invoiceHistoryListDataPDF"
                        onClick={()=>ctx.DownloadPdfInvoice(data.fakturaid)}
                        ><FaFilePdf style={{ color: 'red', fontSize: '16px' }} /></div>
                         
                         <div
  className="invoiceHistoryListData"
  onClick={() => {
    const width = 600;
    const height = 800;
    const left = (window.screen.width - width) / 2;
    const top = (window.screen.height - height) / 2;
    window.open(
      data.receiptUrl,
      '_blank',
      `width=${width},height=${height},top=${top},left=${left},resizable=yes,scrollbars=yes,noopener,noreferrer`
    );
  }}
>
   {data.receiptUrl&&data.receiptUrl.length>0?
  <IoReceiptOutline className="invoiceHistoryListIcon" />
    :<MdOutlinePending  className="invoiceHistoryListIconPending"/>}
</div>

                          <div className="invoiceHistoryListData">
                            {data.payStatus === 1 ? ctx.isNotPayedText : data.payStatus === 2 ? ctx.isPayedText : ''}</div>
                        

                    </div>
                ))}
        </div>
        </div>
    )
}



function InvoiceRows(){
    const ctx = useContext(MyContext);

    const[getInvoiceId,setInvoiceId]=useState(0)
    const[getInvoiceServiceId,setInvoiceServiceId]=useState(0)
// here we will account with currency xchange
let xchangeRate=ctx.getCurrencyInfo[0].xchangeRate
let currencySymbol=ctx.getCurrencyInfo[0].currencySymbol

// here we will sum all rows who has a creditType='F'
let invoiceTotalSum=ctx.getInvoiceRowInfo.filter(data => data.invoiceType === 'F').reduce((acc, data) => acc + Math.round(data.summa/xchangeRate), 0)
// same for kredit
let invoiceTotalKredit=ctx.getInvoiceRowInfo.filter(data => data.invoiceType === 'K').reduce((acc, data) => acc + Math.round(data.summa/xchangeRate), 0)
let invoicetotalsum=invoiceTotalSum-invoiceTotalKredit

    return(
     <><div className="invoiceSelectWrapper">
<select className="invoiceSelectList" onChange={(e) => setInvoiceId(Number(e.target.value),setInvoiceServiceId(0))}>
  <option value="0">{ctx.chooseInvoiceText}</option>
{ctx.getAccountInformation
  .filter((value, index, self) => self.findIndex(item => item.fakturaid === value.fakturaid) === index)
  .map(({ fakturaid, createDate }, index) => (
    <option key={index} value={fakturaid}>{`${fakturaid} - ${new Date(createDate).toLocaleDateString()}`}</option>
  ))
}
</select></div>

        <div className="invoiceRowsTopWrapper">
        <div className="invoiceRowsTop">
             {getInvoiceId > 0 ? `${ctx.invoiceRowsText} ${' '} ${getInvoiceId}` :`${ctx.allInvoiceRowsText}`}
       </div>
       </div>

                <div className="invoiceRowsHeaderWrapper">
                    <div className="invoiceRowfakturaid">{ctx.invoiceRowsId}</div>
                    <div className="invoiceRowServiceid">{ctx.invoiceRowsService}</div>
                    {/* <div className="invoiceRowServiceMax">{ctx.invoiceRowsMax}</div>
                    <div className="invoiceRowRequests">{ctx.invoiceRowsRequest}</div>
                    <div className="invoiceRowDebitQty">{ctx.invoiceRowsDebitQty}</div> */}
                    <div className="invoiceRowSumma">{ctx.invoiceRowsPrice}</div>
                    <div className="invoiceRowDate">{ctx.invoiceRowsTotal}</div>
                    <div className="invoiceRowType">{ctx.invoiceRowsType}</div>
                    
                </div>

                <div className="invoiceRowsListWrapper">
                    {ctx.getInvoiceRowInfo
                    .filter(data => getInvoiceId === 0 || data.fakturaid === getInvoiceId)
                    .map((data, index) => (
                        <div key={index} className="invoiceRowsList">
                            <div className="invoiceRowfakturaid">{data.fakturaid}</div>
                            <div className="invoiceRowServiceid"
                            onClick={()=>setInvoiceServiceId(data.serviceid)}
                            >{data.serviceid}</div>
                            {/* <div className="invoiceRowServiceMax">{data.maxRequest}</div>
                            <div className="invoiceRowRequests">{data.totalRequest}</div>
                            <div className="invoiceRowDebitQty">{data.debitQty}</div> */}
                            <div className="invoiceRowDate">{(data.price/xchangeRate).toFixed(2)}</div>
                            {data.invoiceType==='F'?
                            <div className="invoiceRowSumma">{Math.round(data.summa/xchangeRate)}</div>
                            // here we must turn the amount to a minus value
                            :<div className="invoiceRowSumma">{-Math.round(data.summa/xchangeRate)}</div>}

                            <div className="invoiceRowType">{data.invoiceType}</div>

                        </div>
                    ))}
                </div>
                <div className="invoiceServiceNameWrapper">
                    <div className="invoiceRowsTotalSum">{ctx.invoiceRowsTotalSum} &nbsp;&nbsp;{currencySymbol} &nbsp;{invoicetotalsum}


                    </div>
                    </div>




                {getInvoiceServiceId>0?
                <div className='invoiceServiceNameWrapper'>
                <div className="invoiceServiceName">{ctx.getServiceInformation.find(service => service.serviceid === getInvoiceServiceId)?.benaemn || ''}
                </div>
            </div>:''}


            </>
    )
}


function InvoiceSettings(){
    const ctx = useContext(MyContext);
    let sliderVal=ctx.getMaxValueSlider



    let userBillingAdress = ctx.getUserBillingAdressInfo[0].address||''
    let userBillingPostalCode = ctx.getUserBillingAdressInfo[0].postalCode||''
    let userBillingCity = ctx.getUserBillingAdressInfo[0].city||''
    let userBillingRegion = ctx.getUserBillingAdressInfo[0].region||''
    let userBillingCountry = ctx.getUserBillingAdressInfo[0].country||''
    let userBillingCompany = ctx.getUserBillingAdressInfo[0].company||''
    let userNameInfo = (ctx.getUserBillingAdressInfo[0].givenName || '') + ' ' + (ctx.getUserBillingAdressInfo[0].familyName || '');

    let taxId = ctx.getUserBillingAdressInfo[0].taxId||''
    let invoiceMaxVal=ctx.getUserBillingAdressInfo[0].invoiceMaxVal||''
    return(
        <>
        <div className="invoiceSettingsTop">
        <div className="invoiceSettingsHeaderWrapper">
            <div className="invoiceSettingsHeader">{ctx.settingsHeaderText}</div>
        </div>

        <div className="invoiceSettingsHeaderWrapper">
        <div className="invoiceSettingsHeader1">
            <div className="invoiceSettinsHeaderText">{ctx.companyAndPersonalText}</div>
            <div className="invoiceSettingspLine"></div>
        </div>
        <div className="invoiceSettingsListInfo">{ctx.addressInstructionText}</div>
        <div className="invoiceSettingsListInfo"></div>
   </div>
   <div className="invoiceSettingsCustomerAddressWrapper">
        <div className="invoiceSettingsCustomerAddress">{userBillingCompany}</div>
        <div className="invoiceSettingsCustomerAddress">{userNameInfo}</div>
        <div className="invoiceSettingsCustomerAddress">{userBillingAdress}</div>
        <div className="invoiceSettingsCustomerAddress">{userBillingPostalCode}&nbsp;&nbsp;{userBillingCity}</div>
        <div className="invoiceSettingsCustomerAddress">{userBillingRegion}&nbsp;&nbsp;{userBillingCountry}</div>
        
        <div className="invoiceSettingspLine"></div>
        <div className="invoiceSettingsTaxIdWrapper">
            <div className="invoiceSettingsTaxIdHeader">{ctx.taxIdentificationVATText}</div>
            <div className="invoiceSettingsTaxIdText">{ctx.taxIdInformationText}</div>

            <input type="text" className="invoiceSettingsTaxIdInput" value={taxId}
             placeholder={taxId.length<1?"Skriv in ditt skatte id här":""}
            onChange={(e)=>ctx.setTaxIdHandler(e.target.value)}/>
        </div>
        <div className="invoiceSettingsTaxidSaveWrapper">
        <button className="invoiceSettingsTaxidSave">{ctx.registerTaxIdButtonText}</button>
        </div>
        <div className="invoiceSettingspLine1"></div>
        {/* <div className="invoiceSettingsBillingAlertWrapper">
            <div className="invoiceSettingsBillingAlertHeader">{ctx.maxValueWarningText}</div>
            <div className="invoiceSettingsTaxIdText">{ctx.actiVateMaxValueText}</div>
        </div>
        <div className="invoiceSettingsSliderWrapper">
        <input type="text" className="invoiceSettingMaxValueAlertInput"
        value={ctx.getMaxValue}
        onChange={(e)=>ctx.setMaxValue(e.target.value)}
        />

        <label className="switch">
      <input type="checkbox" onChange={ctx.toggleMaxValueSlider}  checked={ctx.getMaxValueSlider}/>
      <span className="slider round"></span>
    </label>
    </div>
    <div className="invoiceSaveMaxValueButtonWrapper">
    <div className="invoiceSaveMaxValueButton"
    onClick={ctx.InvoiceMaxVal}
    disabled={ctx.getMaxValue<1 || ctx.getMaxValue.length<1}
    >{ctx.maxValueButtonSaveText}</div>
    <div className="invoiceEndFooter"></div>
</div></div></div> */}
</div></div>
        </>

    )
}




function Pass(){
    const ctx = useContext(MyContext);
    const [showPassword, setShowPassword] = useState(false);
    const [reset,setReset]=useState(false)
    const passHeader=reset===false?ctx.passwordHeaderText:ctx.resetPasswordHeaderText
    const saveButtonText=reset===false?"Save":"Reset"
    return(
        <div className="psTop">
            <div className="psHeaderWrapper">
            <div className="psPassHeader">{passHeader}</div>
        </div>


        <div className="psInputWrapper">
        <label htmlFor="oldpass" className={reset===false?"pspassLabel":"pspassLabel1"}>{ctx.passwordValidText}</label>
          <input type={showPassword ? "text" : "password"}
          id="oldpass" className={reset===false?"psInput":"psInput1"} value={ctx.getOldpass}
          onChange={(e)=>ctx.setNewPasswordHandler(e.target.value,1)}

          />




        <label htmlFor="newpass" className="pspassLabel">{ctx.newPasswordText}</label>
          <input type={showPassword ? "text" : "password"} id="newpass" className="psInputNew"
          value={ctx.getNewpass}
          onChange={(e)=>ctx.setNewPasswordHandler(e.target.value,2)}
          />




        <label htmlFor="confirmpass" className="pspassLabel">{ctx.confirmPasswordText}</label>
          <input type={showPassword ? "text" : "password"} id="confirmpass" className="psInputConfirm"
          value={ctx.getConfirmpass}
          onChange={(e)=>ctx.setNewPasswordHandler(e.target.value,3)}
          />


          </div>
          <label className="pscheckbox">
                <input type="checkbox" checked={showPassword} onChange={() => setShowPassword(!showPassword)} />
                {ctx.showPasswordText}
            </label>

            <div className="psSubmitbuttonWrapper">
                <button className={ctx.getOldpass.length<1 || ctx.getNewpass.length<1 || ctx.getConfirmpass.length<1?"psSubmitbutton1":"psSubmitbutton"}
                onClick={()=>ctx.saveNewPasswordHandler(reset)}
              disabled={reset ? (ctx.getNewpass.length < 1 || ctx.getConfirmpass.length < 1) : (ctx.getOldpass.length < 1 || ctx.getNewpass.length < 1 || ctx.getConfirmpass.length < 1)}
              >{ctx.savePasswordButtonText}</button>
                </div>

                <div className="psSubmitbuttonWrapper">
                <fieldset className="psEmailFieldset"><legend className="psEmailLegend">{ctx.iforgotMyPasswordText}</legend>
                <input type="checkbox" className="psEmailSendSubmitButto"
                onClick={()=>setReset(prevReset => !prevReset)}

                />
                </fieldset>
                </div>

        </div>
    )
}
// function XtraTop(){

//     const[getInvoiceTab,setInvoiceTab]=useState(1)
//  return(
// <div className="psinvoiceTop">
//     <div className="psInvoiceTabTop">

//         <button className={`mobileTab ${getInvoiceTab===1?'active':''}`}
//         onClick={()=>setInvoiceTab(1)}>Support</button>

//                <button className={`psinvoiceTab psinvoicelastButton ${getInvoiceTab===2?'active':''}`}
//         onClick={()=>setInvoiceTab(2)}>Inställnigar</button>


//     </div>

//     <div className="psinvoiceTabContent">
//         {getInvoiceTab===1 ? <Support/> : ''}
//         {getInvoiceTab===2 ? <InvoiceSettings/> : ''}
//         </div>
// </div>

//  )


// }
function SupportHeader(){
    const[getSupportTab,setSupportTab]=useState(2)
    return(
   <div className="psinvoiceTop">
       <div className="psInvoiceTabTop">
   
           <button className={`mobileTab ${getSupportTab===1?'active':''}`}
           onClick={()=>setSupportTab(1)}>Mina ärenden</button>
   
                  <button className={`psinvoiceTab psinvoicelastButton ${getSupportTab===2?'active':''}`}
           onClick={()=>setSupportTab(2)}>Support message</button>

   
   
   
   
       </div>
   
       <div className="psinvoiceTabContent">
           {getSupportTab===1 ? <SupportHistory/> : ''}
           {getSupportTab===2 ? <Support/> : ''}
   
           </div>
   </div>
   
    )
}
function SupportHistory(){
    const ctx = useContext(MyContext);
    return(
        <div className="supportHistoryTop">
            <div className="supportHistoryHeaderWrapper">
            <div className="supportHistoryHeader">{ctx.supportRequestText}</div>
             </div>

             <div className="supportHistoryListWrapper">
            
{ctx.getSupportTickets.length > 0 && (
  <div className="supportHistoryList">
    <div className="supportHistoryServiceId1">{ctx.supportHistoryServiceIdText}</div>
    <div className="supportHistoryCreateDate1">{ctx.supportHistoryBenaemnText}</div>
    <div className="supportHistoryStatus1">{ctx.supportHistoryStatusText}</div>
  </div>
)}
{ctx.getSupportTickets.map((data, index) => (
  <div key={index} className="supportHistoryList">
    <div className="supportHistoryServiceId">{data.supportid}</div>
    <div className="supportHistoryCreateDate" onClick={()=>ctx.GetSupportTicketHandler(data.supportid)}>
      {data.kategoribenaemn}
    </div>
    <div className="supportHistoryStatus">{data.benaemn}</div>
  </div>
))}


<div className="supportShowMessageTop">
<div className="supportShowMessageWrapper">
    <div className="supportShowMessageHeader">
        <div className="supportShowMessageHeader1">{ctx.supportHistoryCategoryText}:&nbsp;</div>
       <div className="supportShowMessageHeader1">
       {ctx.getSupportTicket && ctx.getSupportTicket[0] && ctx.getSupportTicket[0].benaemn1 || 'no ticket'}</div>
     </div>
     <div className="supportShowMessageWrapper">
        <div className="supportTitleText">{ctx.messagesSentToSupportText}</div>
  
        <textarea className="supportShowMessageTextArea" 
        onChange={(e)=>ctx.UpdatesupportMessageHandler(e.target.value)}
       value={ctx.getSupportTicket && ctx.getSupportTicket[0] && ctx.getSupportTicket[0].benaemn || ''}>
        </textarea>

        <button className="supportShowMessageButton"
        onClick={()=>ctx.UpdateClientSideSupportTicket(ctx.getChosenTicketId)}
        >{ctx.supportButtonAnswerText}</button>


     </div>

</div>
</div>




             </div>
        </div>
      
    )
}


function Support(){
    const ctx = useContext(MyContext);
    return(

            <div className="supportHeaderTop">
                <div className="supportHeaderWrapper">
                     <div className="supportTop">{ctx.supportMText}</div>
                </div>
            
             <div className="supportMessageWrapper">
                    <div className="supportMessageHeader">{ctx.supportMessageText}</div>
                    <div className="supportMessageInputWrapper">
                    
                        <select className="supportMessageSelect" onChange={(e)=>ctx.supportCategoryHandler(e.target.value)}>
                            <option value="0">{ctx.supportMessageSelectText}</option>
                            {ctx.getSupportCategories.map((data,index)=>(
                                <option key={index} value={data.kategoriid}>{data.benaemn}</option>
                            ))}
                     </select>
                        <textarea className="supportMessageTextArea" placeholder={ctx.supportMessagePlaceholderText}
                         onChange={(e)=>ctx.supportMessageHandler(e.target.value)}
                         value={ctx.getSupportMessage}
                        
                        
                        />
                  
                    <div className="supportMessageButtonWrapper">
                        <button className={ctx.getSupportCategory===0 || ctx.getSupportMessage.length<1?"supportMessageButton1":"supportMessageButton"}
                        onClick={()=>ctx.SendSupportMessageHandler()}
                        disabled={ctx.getSupportCategory===0 || ctx.getSupportMessage.length<1}
                        >{ctx.supportMessageSendText}</button>
                    </div>
                    </div>
             </div>

        </div>
    )
}
function TjansterDebitering(){
    const ctx = useContext(MyContext);


    return(
     <div className="tjansterDebTop">
    <div className="tjansterDebWrapper">
        <div className="tjansterDebHeaderText">{ctx.userDebitServices}</div>
    </div>

    <div className="tjansterDebListWrapper">
        {/* Header */}
        <div className="tjansterDebRow">
            <div className="tjansterDebService1">{ctx.debserviceidText}</div>
            <div className="tjansterDebBenaemn1">{ctx.debserviceBenaemningText}</div>
            <div className="tjansterDebQty1">{ctx.debserviceQtyText}</div>
            <div className="tjansterDebMax1">{ctx.debserviceMaxText}</div>
            <div className="tjansterDebUsable1">{ctx.debserviceUsableText}</div>
            <div className="tjansterDebActive1">{ctx.debserviceStatusText}</div>
        </div>

        {/* Data */}
        {ctx.getUserTotalUsage.map((data, index) => (
            <div key={index} className="tjansterDebRow">
                <div className="tjansterDebService">{data.serviceid}</div>
                <div className="tjansterDebBenaemn">{data.benaemn}</div>
                <div className="tjansterDebQty">{data.antal}</div>
                <div className="tjansterDebMax">{data.maxVal}</div>
                <div className="tjansterDebUsable">{Math.max(0, data.maxVal - data.antal)}</div>
                <div className="tjansterDebActive">&nbsp;{data.active}</div>
            </div>
        ))}
    </div>
</div>
    )
   
}

function LiveTabs(){
    let ctx=useContext(MyContext)
    const [activeTab, setActiveTab] = useState('tab1');


    return (
            <div className="xjLive-tabs-container">
                <div className="xjLive-tabs-header">
                    <div
                        className={`xjLive-tab ${activeTab === 'tab1' ? 'xjLive-active' : ''}`}
                        onClick={() => setActiveTab('tab1')}
                    >
                    Live account
                    </div>
                    <div
                        className={`xjLive-tab ${activeTab === 'tab2' ? 'xjLive-active' : ''}`}
                        onClick={() => setActiveTab('tab2')}
                    >
                    Live account setup
                    </div>
                </div>
                <div className="xjLive-tabs-content">
                    {activeTab === 'tab2' && <div className="xjLive-tab-content">
                        <RecipePublisherSignup
                        step={ctx.step}
                        setStep={ctx.setStep}
                        image={ctx.image}
                        setImage={ctx.setImage}
                        profileText={ctx.profileText}
                        setProfileText={ctx.setProfileText}
                        paypalEmail={ctx.paypalEmail}
                        setPaypalEmail={ctx.setPaypalEmail}
                        agreedToTerms={ctx.agreedToTerms}
                        setAgreedToTerms={ctx.setAgreedToTerms}
                        isLoading={ctx.isLoading}
                        setIsLoading={ctx.setIsLoading}
                        handleImageUpload={ctx.handleImageUpload}
                        preview={ctx.preview}
                        UploadLiveProfileInfoHandler={ctx.UploadLiveProfileInfoHandler}
                        getCountry={ctx.getCountry}
                        
                    
                        
                        
                        /></div>}
                    {activeTab === 'tab1' && <div className="xjLive-tab-content">
                        <UserSales
                            userSalesTotalMonth={ctx.userSalesTotalMonth}
                            userTotalSales={ctx.userTotalSales}
                            userTotalSalesInMonth={ctx.userTotalSalesInMonth}
                            userKreditComission={ctx.userKreditComission}
                            bakesoftPayoutCharge={ctx.bakesoftPayoutCharge}
                            bakesoftKreditPrice={ctx.bakesoftKreditPrice}
                            PayComissionHandler={ctx.PayComissionHandler}
                        /></div>}
                </div>
            </div>
    );
};


export {MyAccount,Adressinfo,Tjanster,DataAnvandning,MinData,
    Betalning,MinaFakturor,Pass,InvoiceOverView,InvoiceHistory,
    InvoiceSettings,BetalningFaktura,Konto,
    Support,InvoiceRows,KontoTop,TjansterDebitering,SupportHeader,SupportHistory,CompletePage,
    CallbackCompletePage,CallbackCompletePageFailed,LiveTabs
}
