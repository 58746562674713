import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './LicenseAgreement.scss';
import FreelancerAgreementEng from './FreelanceAgreementEng';
import FreelancerAgreementJap from './FreelanceAgreementJap';
import FreelancerAgreementSwe from './FreelanceAgreementSwe';

// Agreement components
const SWEAgreement = () => (
  <div>
  <FreelancerAgreementSwe />
  </div>
);

const JAPAgreement = () => (
  <div>
 <FreelancerAgreementJap />
  </div>
);

const ENAgreement = () => (
  <div>
<FreelancerAgreementEng />
  </div>
);

const languages = [
  { value: 'en', label: 'English', component: ENAgreement },
  { value: 'ja', label: 'Japanese', component: JAPAgreement },
  { value: 'se', label: 'Swedish', component: SWEAgreement },
];

const LicenseAgreement = () => {
  const [language, setLanguage] = useState(languages[0]);

  const handleLanguageChange = (event) => {
    const selectedLanguage = languages.find(lang => lang.value === event.target.value);
    setLanguage(selectedLanguage);
  };

  const AgreementComponent = language.component;

  return (
    <div className="license-agreement">
      <header className="license-agreement__header">
        <h1 className="license-agreement__title">Bakesoft Live Freelancer Agreement</h1>
        <select
          className="license-agreement__language-select"
          value={language.value}
          onChange={handleLanguageChange}
          aria-label="Select language"
        >
          {languages.map((lang) => (
            <option key={lang.value} value={lang.value}>
              {lang.label}
            </option>
          ))}
        </select>
      </header>
      <main className="license-agreement__content">
        <div className="license-agreement__agreement">
          <AgreementComponent />
        </div>
      </main>
      <footer className="license-agreement__footer">
        <Link to="/MyAccount" className="license-agreement__back-button">
          Back to Sign Up
        </Link>
        <p className="license-agreement__footer-text">
          Please read the agreement carefully before signing up.
        </p>
      </footer>
    </div>
  );
};

export default LicenseAgreement;

