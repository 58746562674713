
// FreelancerAgreement.js
import React from "react";
import "./FreelancerAgreement.scss";

const FreelancerAgreementJap = () => {
  return (
    <div className="agreement-container">
      <h1 className="agreement-title">レシピクリエイターのためのフリーランサー契約</h1>
      <p className="agreement-intro">
        <strong>本フリーランサー契約（「契約」）</strong> は以下の当事者間で締結されます：
      </p>
      <ul className="agreement-parties">
        <li>
          <strong>Hellström Trading i Göteborg AB</strong>（スウェーデンに登録された企業、組織番号
          <strong>556572-0413</strong>、本社所在地 <strong>スウェーデン、ヨーテボリ</strong>。
          以下「当社」または「私たち」とします）。
        </li>
        <li>
          <strong>レシピクリエイター</strong>（以下「ライセンサー」または「あなた」とします）。
        </li>
      </ul>
      <hr />

      <section>
        <h2>1. 定義</h2>
        <ul>
          <li>
            <strong>プラットフォーム</strong>：<em>www.bakesoft.se</em> および <em>live.bakesoft.se</em> を指し、当社が所有・運営しています。
          </li>
          <li>
            <strong>レシピ</strong>：あなたが作成したテキスト、画像、動画を含むオリジナルの料理レシピやコンテンツ。
          </li>
          <li>
            <strong>ライセンサー</strong>：プラットフォームにレシピをアップロードし、当社にライセンスを与えるフリーランサー。
          </li>
          <li>
            <strong>エンドユーザー</strong>：live.bakesoft.se でレシピを有料で解除するユーザー。
          </li>
          <li>
            <strong>収益</strong>：エンドユーザーがレシピを解除するために支払った金額。
          </li>
        </ul>
      </section>

      <section>
        <h2>2. 当事者の関係</h2>
        <p>
          あなたは<strong>独立した請負業者</strong>であり、当社の<strong>従業員ではない</strong>ことを認識します。
          本契約は雇用関係、パートナーシップ、または代理関係を構築するものではありません。
        </p>
      </section>

      <section>
        <h2>3. ライセンスおよび所有権</h2>
        <p>
          あなたはレシピに対する全ての知的財産権を保持します。レシピをアップロードすることで、当社に
          <strong>非独占的、世界的、ロイヤリティ共有ライセンス</strong>を付与します：
        </p>
        <ul>
          <li>プラットフォーム上でレシピを表示、共有、および配布すること。</li>
          <li>エンドユーザーが有料でアクセスできるようにすること。</li>
        </ul>
      </section>

      <section>
        <h2>4. 支払い条件</h2>
        <ul>
          <li>
            <strong>収益分配</strong>：あなたは<strong>純収益の30%</strong>を受け取ります。
            <strong>1ドルの取引手数料</strong>が適用されます。
          </li>
          <li>
            <strong>支払い方法</strong>：支払いは<strong>PayPal</strong>のメールアカウントに対して行われます。
          </li>
          <li>
            <strong>支払いスケジュール</strong>：最小支払い額は<strong>5ドル</strong>です。
          </li>
        </ul>
      </section>

      <section>
        <h2>5. ライセンサーの責務</h2>
        <p>あなたは次のことを約束します：</p>
        <ul>
          <li>オリジナルコンテンツをアップロードすること。</li>
          <li>第三者の知的財産法を遵守すること。</li>
          <li>収益を報告し、税法に準拠すること。</li>
        </ul>
      </section>

      <section>
        <h2>6. 会社の権利および責務</h2>
        <ul>
          <li>ガイドラインに違反するレシピを審査および削除すること。</li>
          <li>不正使用や詐欺のアカウントを停止すること。</li>
        </ul>
      </section>

      <section>
        <h2>7. 責任の制限</h2>
        <p>
          当社は技術的エラー、エンドユーザーの行動、第三者の請求により生じた収益損失について責任を負いません。
        </p>
      </section>

      <section>
        <h2>8. フォースマジュール</h2>
        <p>
          自然災害、ストライキ、パンデミックなどの予期しない事象による遅延については、いずれの当事者も責任を負いません。
        </p>
      </section>

      <section>
        <h2>9. 契約の終了</h2>
        <p>
          いずれの当事者も<strong>30日間の書面通知</strong>で契約を終了できます。終了時に未払い収益が処理されます。
        </p>
      </section>

      <section>
        <h2>10. 準拠法および紛争</h2>
        <p>本契約は<strong>スウェーデンの法律</strong>に準拠します。</p>
      </section>

      <footer className="agreement-footer">
        <p>
          <em>“本フリーランサー契約に同意します”</em> のチェックボックスを選択することで、本契約の条項に同意したものとみなされます。
        </p>
        <p><strong>Hellström Trading i Göteborg AB</strong></p>
        <p>組織番号: 556572-0413</p>
        <p>住所: スウェーデン、ヨーテボリ</p>
      </footer>
    </div>
  );
};

export default FreelancerAgreementJap;
