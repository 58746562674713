import React,{useEffect,useState} from 'react';
import './HeaderMenu.css'
import { Link, useLocation } from 'react-router-dom';
// import Baka from '../images/baka.jpg';
import useDeviceDetection from '../hooks/useDeviceDetection';
import useHeaderStyle from '../hooks_styles/useHeaderStyle';
import languages from '../AppLanguage/AppLanguage';
import CryptoJS from 'crypto-js';
import bs from '../images/bs.jpeg'
import { TfiHelpAlt } from "react-icons/tfi";
import { MdOutlineLiveHelp } from "react-icons/md";
import { FaRegQuestionCircle } from "react-icons/fa";
import { FcInfo } from "react-icons/fc";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { FcCustomerSupport } from "react-icons/fc";
import { RotatingLines } from 'react-loader-spinner';
import { usePostData } from '../hooks/useGetData';

function Header(props){


const {GetUser,handleSignOut,StatusLevel,statusval}=props
const[userCheck,setuserCheck]=useState(false)
const[headerClass,setheaderClass]=useState([{}])
const [imageLink,setImageLink]=useState(localStorage.getItem('image_link')||'')
const[getCountry,setCountry]=useState('se')
const[user,setUser] = useState(0);
const [textareaValue, setTextareaValue] = useState('');
const [menuOpen, setMenuOpen] = useState(false);
const [isHelperOpen, setIsHelperOpen] = useState(false);
const [spinnerLoading, setSpinnerLoading] = useState(false);
const[getAiresponse,setAiresponse]=useState('')

const location = useLocation();
const currentPath = location.pathname;

useEffect(() => {
  setTextareaValue(currentPath); // this is the path url that we use to get the information from the server
  setIsHelperOpen(false); // we close the helper when the page changes
}, [currentPath]);


useEffect(()=>{
  let country = localStorage.getItem('country')||'se' // this is the country code
  setCountry(country)
},[])

const { isIpad, isAndroid,isWindows } = useDeviceDetection(); // we detect the device type here
const {navbarStylesIpad10th,navbarStylesAndroid,navbarStylesWindows} = useHeaderStyle(getCountry)


useEffect(() => {
  const handleStorageChange = () => {
    setImageLink(localStorage.getItem('image_link') || '');
  };

  window.addEventListener('storage', handleStorageChange);

  // Clean up the event listener when the component unmounts
  return () => {
    window.removeEventListener('storage', handleStorageChange);
  };
}, []);


const checkUser = () => {
  let storedValue = localStorage.getItem('qba');
  let decryptedData = false;
 
  if (storedValue) {
    let bytes  = CryptoJS.AES.decrypt(storedValue.toString(), '202308112023081120230811');
    decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
   
   }else{
      decryptedData=0
   }
  return decryptedData;
};


useEffect(()=>{
  if(isIpad===true){
      setheaderClass(navbarStylesIpad10th) // we set the styles for the device type here
  }else if(isAndroid===true){
      setheaderClass(navbarStylesAndroid)
    }else if(isWindows===true){
      setheaderClass(navbarStylesWindows)
   }else{
      setheaderClass(navbarStylesWindows)

  }
},[isIpad,isAndroid,isWindows,imageLink,userCheck])





const selectedCountry = languages[getCountry]


const {
  headermenuHome,
  headermenuLogout,
  headermenuIngrediensList,
  headermenuRecipeList,
  headermenuProductList,
  headermenuIngredients,
  headermenuIngredientCategories,
  headermenuRecipeCategories,
  headermenuProductCategories,
  headermenuMyRecipes,
  headermenuCreateReceipemanually,
  headermenuCreateRecipeWithAI,
  headermenuCreateProduct,
  headermenuProductStatistics,
  headermenuWorkWithProduction,
  headermenuTotalNumbers,
  headermenuRecipeSummary,
  headermenuIngredientSummary,
  headermenuPriceList,
  headermenuPageSettings,
  lists,
  InfoAboutUs,
  headermenuproducts,
  headermenuproduction,
  headermenurecipe,
  headermenuUserDataPolicy,
  headermenuRecipeIdeas,
  headermenuMyAccount,
  headermenuRecipeScroll,
  headermenuLiveTerms,
} = selectedCountry.headermenubar||{};

//noHelpInformationText

const{
  noHelpInformationText,
  FetchingHelpInstructionsText,
}=selectedCountry.confirmationMessages||{};


let countryClass=''
if(getCountry==='ja'){
  countryClass='startlinkJAP'
}else{
  countryClass='startlink'
}

const handleHelperClick = () => {
  setIsHelperOpen(!isHelperOpen);
  setAiresponse('')
};



async function GetHelperInfo(){
  setSpinnerLoading(true)
  try{
    const response = await usePostData('helpMessage', {pageLinkName:textareaValue})
    console.log(response)
    if(response.status===200){
      setAiresponse(response.data)
      setSpinnerLoading(false)
    }else if(response.status===290){
      setAiresponse(noHelpInformationText)
      setSpinnerLoading(false)
    }

  }catch(error){
    setAiresponse(noHelpInformationText)
    setSpinnerLoading(false)
  }
}








  useEffect(()=>{
    if(textareaValue!=='/login' && isHelperOpen===true){
    GetHelperInfo()
    }
  }
  ,[isHelperOpen])

   return(

<div className={'navbar'}>
  
      <div className="dropdown">
            <div className="logo">Bakesoft</div>
     </div>

<div className="dropdown">
    <button style={headerClass.dropbtn}>{headermenuHome}</button>
      <div className="dropdown-content">
                <Link to='/'>{headermenuHome}</Link>
                {checkUser()>0?<Link to='/Logout'>{headermenuLogout}</Link>:''}
                {checkUser()===0?<Link to='/signUp'>{InfoAboutUs}</Link>:''}  
      </div>
</div>


<div className="dropdown">
    <button style={headerClass.dropbtn}>{lists}</button>
    <div className="dropdown-content" style={headerClass.linkStyle}>
              {checkUser()>0?<Link to='/ListIngred'>{headermenuIngrediensList}</Link>:''}
              {checkUser()>0?<Link to='/ListRecept'>{headermenuRecipeList}</Link>:''}
              {checkUser()>1?<Link to='/ListProdukt'>{headermenuProductList}</Link>:''}
              
    </div>
</div>

   
<div className="dropdown">
    <button style={headerClass.dropbtn}>{headermenuIngredients}</button>
    <div className="dropdown-content" style={headerClass.linkStyle}>
            {checkUser()>0?<Link to='/ingredNew'>{headermenuIngredients}</Link>:''}
            {checkUser()>0?<Link to='/IngredCategoriesNew'>{headermenuIngredientCategories}</Link>:''}
    </div>
</div>
  
<div className="dropdown">
    <button style={headerClass.dropbtn}>{headermenurecipe}</button>
    <div className="dropdown-content" style={headerClass.linkStyle}>
          {checkUser()>0?<Link to='/RecipeIdeas'>{headermenuRecipeIdeas}</Link>:''}
          {checkUser()>0?<Link to='/SearchRecepyNew2'>{headermenuMyRecipes}</Link>:''}
          {checkUser()>0?<Link to='/CreateManRecepyNew'>{headermenuCreateReceipemanually}</Link>:''}
          {checkUser()>0?<Link to='/createnewrecepy'>{headermenuCreateRecipeWithAI}</Link>:''}
          {checkUser()>0?<Link to="/RecepycategoriesNew">{headermenuRecipeCategories}</Link>:''}
    </div>
</div>



<div className="dropdown">
    <button style={headerClass.dropbtn}>{headermenuproducts}</button>
    <div className="dropdown-content" style={headerClass.linkStyle}>
          {checkUser()>1?<Link to="/CreateproductNew">{headermenuCreateProduct}</Link>:''}  
          {checkUser()>1? <Link to="/ProductcategoriesNew">{headermenuProductCategories}</Link>:''}
          {checkUser()>1?<Link to="/statisticsbyproductNew">{headermenuProductStatistics}</Link>:''}
      
    </div>
</div>



 <div className="dropdown">
    <button style={headerClass.dropbtn}>{headermenuproduction}</button>
    <div className="dropdown-content" style={headerClass.linkStyle}>
          {checkUser()>1?<Link to="/CreateproductionNew">{headermenuWorkWithProduction}</Link>:''}
          {checkUser()>1?<Link to="/TotalProductionNew">{headermenuTotalNumbers}</Link>:''}
          {checkUser()>1?<Link to="/ProdArbSumNew">{headermenuRecipeSummary}</Link>:''}
          {checkUser()>1?<Link to="/IngrediensTotalerNew">{headermenuIngredientSummary}</Link>:''}
    </div>
</div>



 
<div className="dropdown">
    <button style={headerClass.dropbtn}>{headermenuPriceList}</button>

    <div className="dropdown-content" style={headerClass.linkStyle}>
           {checkUser()>1?<Link to="/PriceListNew">{headermenuPriceList}</Link>:''}
    </div>

</div>

  
 
<div className="dropdown" style={headerClass.linkStyle}>
    <button style={headerClass.dropbtn}>{headermenuPageSettings}</button>

        <div className="dropdown-content" style={headerClass.linkStyle}>
              {checkUser()>0?<Link to="/MySettings">{headermenuPageSettings}</Link>:''}
              {checkUser()>0?<Link to="/MyAccount">{headermenuMyAccount}</Link>:''}
              {checkUser()>0?<Link to="/UserDataPolicy">{headermenuUserDataPolicy}</Link>:''}
              {checkUser()>0?<Link to="/liveterms">{headermenuLiveTerms}</Link>:''}
        </div>
</div>



  {/* {checkUser()>0?<div className="dropdown" style={headerClass.linkStyle}> */}
    
     {/* <button style={headerClass.dropbtn1}
         onClick={()=>menuHandler()}
     >
           <FcInfo fontSize={30}
           className="helperInfoIcon"
           
           /></button>
               <button style={headerClass.dropbtn1}
         onClick={()=>menuHandler()}
     >
           <FcCustomerSupport fontSize={30}
           className="helperSupportIcon"
           
           /></button> */}
{checkUser()>0?
<div className="header-menu">
      <button className="helperInfoButton" onClick={handleHelperClick}
      title="Help information"
      >
        <FcInfo fontSize={36}  />
      </button>
      {isHelperOpen && (
        <div className="helper-content">
          <HelperFunction getAiresponse={getAiresponse}
          getSpinnerLoading={spinnerLoading}
          FetchingHelpInstructionsText={FetchingHelpInstructionsText}
           />
          
        </div>
      )}
    </div>
    :''}




<div className="imageWrapper">
{imageLink.length>1?<div className="userinfo">
  <img src={imageLink} alt="User Profile" style={headerClass.userPicture}/>
  </div>: <img src={bs} alt="User Profile" className="userinfo"/>}
</div>


</div>





   )

   
}

const HelperFunction = (props) => {
  let{getAiresponse,getSpinnerLoading,
    FetchingHelpInstructionsText

  }=props

  return (
    <div className="hpTopWrapper">
      <div className="hpInfoTop">
        <textarea  className="hpTextArea"
        value={getAiresponse}
        readOnly={true}
        ></textarea>
        

      </div>
      <div className="hpSpinnerContainer">
        <div className="hpSpinner">
          <RotatingLines strokeColor="grey" strokeWidth="2" animationDuration="0.75" width="60" visible={getSpinnerLoading}/>
        </div>
        {getSpinnerLoading===true?
        <p className="hpSpinner-text">{FetchingHelpInstructionsText}</p>
        :''}
    </div>
    </div>
  );
};

export default Header;