

// FreelancerAgreement.js
import React from "react";
import "./FreelancerAgreement.scss";

const FreelancerAgreementEng = () => {
  return (
    <div className="agreement-container">
      <h1 className="agreement-title">FREELANCER AGREEMENT FOR RECIPE CREATORS</h1>
      <p className="agreement-intro">
        <strong>This Freelancer Agreement ("Agreement")</strong> is entered into between:
      </p>
      <ul className="agreement-parties">
        <li>
          <strong>Hellström Trading i Göteborg AB</strong>, a company registered in Sweden
          with organization number <strong>556572-0413</strong> and registered office at
          <strong> Göteborg, Sweden</strong> (hereinafter referred to as “the Company” or
          “We”).
        </li>
        <li>
          <strong>The Recipe Creator</strong> (hereinafter referred to as “the Licensor” or
          “You”).
        </li>
      </ul>
      <hr />

      <section>
        <h2>1. DEFINITIONS</h2>
        <ul>
          <li>
            <strong>Platform</strong>: Refers to <em>www.bakesoft.se</em> and <em>live.bakesoft.se</em>.
          </li>
          <li>
            <strong>Recipe</strong>: A unique food recipe or content created by you, including text, images, or videos.
          </li>
          <li>
            <strong>Licensor</strong>: The freelancer who uploads Recipes to the Platform.
          </li>
          <li>
            <strong>End User</strong>: Users who pay to unlock Recipes on live.bakesoft.se.
          </li>
          <li>
            <strong>Revenue</strong>: Payments received from End Users to unlock Recipes.
          </li>
        </ul>
      </section>

      <section>
        <h2>2. RELATIONSHIP BETWEEN PARTIES</h2>
        <p>
          You acknowledge that you are an <strong>independent contractor</strong> and
          <strong> not an employee</strong> of the Company. This Agreement does not create any employment,
          partnership, or agency relationship.
        </p>
      </section>

      <section>
        <h2>3. LICENSING AND OWNERSHIP</h2>
        <p>
          You retain ownership of all intellectual property rights to your Recipes. By uploading Recipes, you
          grant the Company a <strong>non-exclusive, worldwide, royalty-sharing license</strong> to:
        </p>
        <ul>
          <li>Display, share, and distribute the Recipe on the Platform.</li>
          <li>Allow End Users to view and access the Recipe upon payment.</li>
        </ul>
      </section>

      <section>
        <h2>4. PAYMENT TERMS</h2>
        <ul>
          <li>
            <strong>Revenue Sharing</strong>: You receive <strong>30% of the net revenue</strong>. A
            <strong> $1 transaction fee</strong> applies.
          </li>
          <li>
            <strong>Payment Method</strong>: Payouts are made to your <strong>PayPal</strong> email account.
          </li>
          <li>
            <strong>Payment Schedule</strong>: The smallest payout amount is <strong>$5</strong>.
          </li>
        </ul>
      </section>

      <section>
        <h2>5. LICENSOR RESPONSIBILITIES</h2>
        <p>You agree to:</p>
        <ul>
          <li>Upload original content.</li>
          <li>Ensure content complies with intellectual property laws.</li>
          <li>Report your income and comply with tax regulations.</li>
        </ul>
      </section>

      <section>
        <h2>6. COMPANY RIGHTS AND RESPONSIBILITIES</h2>
        <ul>
          <li>Review and remove Recipes that violate guidelines.</li>
          <li>Suspend accounts for misuse or fraud.</li>
        </ul>
      </section>

      <section>
        <h2>7. LIMITATION OF LIABILITY</h2>
        <p>
          The Company is not liable for revenue loss due to technical errors, End User actions, or
          third-party claims.
        </p>
      </section>

      <section>
        <h2>8. FORCE MAJEURE</h2>
        <p>
          Neither party is liable for delays caused by natural disasters, strikes, pandemics, or other
          unforeseeable events.
        </p>
      </section>

      <section>
        <h2>9. TERMINATION</h2>
        <p>
          Either party may terminate the Agreement with <strong>30 days' written notice</strong>. Final payouts
          will be processed.
        </p>
      </section>

      <section>
        <h2>10. GOVERNING LAW AND DISPUTES</h2>
        <p>This Agreement is governed by the laws of <strong>Sweden</strong>.</p>
      </section>

      <footer className="agreement-footer">
        <p>
          By checking the box <em>“I agree to the Freelancer Agreement”</em>, you acknowledge that you
          have read, understood, and agree to these terms.
        </p>
        <p><strong>Hellström Trading i Göteborg AB</strong></p>
        <p>Organization Number: 556572-0413</p>
        <p>Address: Göteborg, Sweden</p>
      </footer>
    </div>
  );
};

export default FreelancerAgreementEng;
