

'use client'

import React, { useState } from 'react'
import styles from './UserSales.scss'



const ITEMS_PER_PAGE = 10

export default function AnalyticsPage({userSalesTotalMonth,userTotalSales,userTotalSalesInMonth,
  userKreditComission,bakesoftPayoutCharge,bakesoftKreditPrice,PayComissionHandler
}) {
    let recipes=userSalesTotalMonth
    let userComission=userKreditComission[0].kreditComission
    let totalSales=userTotalSales[0].totalSales
    let totalSalesInMonth=userTotalSalesInMonth[0].totalSales
  

  const [currentPage, setCurrentPage] = useState(1)
  const [searchTerm, setSearchTerm] = useState('')

  const filteredRecipes = recipes.filter(recipe =>
    recipe.benaemn.toLowerCase().includes(searchTerm.toLowerCase())
  )

  const totalPages = Math.ceil(filteredRecipes.length / ITEMS_PER_PAGE)
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE
  const paginatedRecipes = filteredRecipes.slice(startIndex, startIndex + ITEMS_PER_PAGE)



  return (
    <div className="analyticsPage">
      <h1 className="title">Live Publisher Dashboard</h1>
      
      <div className="dashboard">
        <div className="metric">
          <h2>Total Credit Sales</h2>
          <p>{totalSales||0}</p>
          <span>Across all recipes</span>
        </div>
        <div className="metric">
          <h2>Monthly Credit Sales</h2>
          <p>{totalSalesInMonth||0}</p>
          <span>This month</span>
        </div>
        <div className="metric">
          <h2>Total Credits In Account</h2>
          <p>{totalSalesInMonth||0}</p>
          <span>Available in account</span>
        </div>
        <div className="metric">
          <h2>Credits accounting 1credit*{parseFloat(userComission)}*{totalSalesInMonth}</h2>
          <p>{(totalSalesInMonth*userComission).toFixed(2)}</p>
          <span>To be paid out{' '}{(totalSalesInMonth*userComission).toFixed(2)}</span>
        </div>
        <div className="metric">
          <h2>Payout Comission Amount</h2>
          <p>{(totalSalesInMonth*userComission).toFixed(2)}</p>
          <span>To be paid out</span>
        </div>
        <div className="metric">
          <h2>Freelancer agreement</h2>
          <p>{userComission}%</p>
          <span>Credit payout charge: {bakesoftPayoutCharge}</span><br></br>
          <span>System credit price: {bakesoftKreditPrice}USD</span>
        </div>
        <div className="metric">
          <h2>Min comission amount</h2>
          <p>5USD</p>
        </div>
        <div className="metric2"
           onClick={()=>PayComissionHandler()}
        >
          <h2>Issue Payment</h2>
          <p>Click to issue payment</p>
          <span>Auto due date payment, last day of month</span>
        </div>
        
      </div>
      

      <div className="recipeList">
        <h2>Recipe Sales</h2>
        <div className="controls">
          <input
            type="text"
            placeholder="Search recipes..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="searchInput"
          />
          <div className="pagination">
            <button
              onClick={() => setCurrentPage(page => Math.max(1, page - 1))}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span>
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={() => setCurrentPage(page => Math.min(totalPages, page + 1))}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </div>
        <table className="table">
          <thead>
            <tr>
              <th>Recipe Name</th>
              <th>Total Sales</th>
              <th>Monthly Sales</th>
            </tr>
          </thead>
          <tbody>
            {paginatedRecipes.map((recipe) => (
              <tr key={recipe.recipeid}>
                <td>{recipe.benaemn}</td>
                <td>{recipe.totalCredits}</td>
                <td>{recipe.totalCreditsMonth}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

