import React, { useState } from 'react';
import { ArrowRight, ArrowLeft, Upload, ExternalLink } from 'lucide-react';
import './RecipePublisherSignup.scss';
import languages from '../AppLanguage/AppLanguage';

const RecipePublisherSignup = ({
    setStep,
    step,
    image,
    setImage,
    profileText,
    setProfileText,
    paypalEmail,
    setPaypalEmail,
    agreedToTerms,
    setAgreedToTerms,
    isLoading,
    setIsLoading,
    handleImageUpload,
    preview,
    UploadLiveProfileInfoHandler,
    getCountry
  }) => {

    const selectedCountry = languages[getCountry];

    const {
      recipePublisherHeaderText,
      setupYourAccountText,
      profileImageText,
      profileDescriptionText,
      selectImageText,
      charText,
      paypalEmailText
  }= selectedCountry.MyAccount||{};


    return (
      <div className="card">
        <div className="card-header">
          <h2 className="card-title">{recipePublisherHeaderText}</h2>
          <p className="card-description">{setupYourAccountText}</p>
        </div>
        <div className="card-content">
          <div className="progress-bar">
            <div className="progress" style={{ width: `${(step / 4) * 100}%` }}></div>
          </div>
          <form>
            {step === 1 && (
              <div className="form-group">
                <label htmlFor="image-upload">{profileImageText}</label>
                <div className="image-upload">
                  <input
                    id="image-upload"
                    type="file"
                    accept="image/*"
                    onChange={handleImageUpload}
                    className="hidden-input"
                  />
                  <button
                    type="button"
                    className="btn btn-outline"
                    onClick={() => document.getElementById('image-upload').click()}
                  >
                    <Upload className="icon" /> {selectImageText}
                  </button>
                  {preview && <img src={preview} alt="Profile" className="preview-image" />}
                </div>
              </div>
            )}
  
            {step === 2 && (
              <div className="form-group">
                <label htmlFor="profile-text">{profileDescriptionText}</label>
                <textarea
                  id="profile-text"
                  placeholder="Tell us about yourself..."
                  value={profileText}
                  onChange={(e) => setProfileText(e.target.value)}
                  maxLength={500}
                  className="textarea"
                />
                <p className="char-count">
                  {profileText.length}{charText}
                </p>
              </div>
            )}
  
            {step === 3 && (
              <div className="form-group">
                <label htmlFor="paypal-email">{paypalEmailText}</label>
                <input
                  id="paypal-email"
                  type="email"
                  placeholder="your@email.com"
                  value={paypalEmail}
                  onChange={(e) => setPaypalEmail(e.target.value)}
                  className="input"
                />
                <a
                  href="https://www.paypal.com/signup"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link"
                >
                  Don't have a PayPal account? Sign up here <ExternalLink className="icon" />
                </a>
              </div>
            )}
  
            {step === 4 && (
              <div className="form-group">
                <div className="checkbox-group">
                  <input
                    type="checkbox"
                    id="terms"
                    checked={agreedToTerms}
                    onChange={(e) => setAgreedToTerms(e.target.checked)}
                    className="checkbox"
                  />
                  <label htmlFor="terms" className="checkbox-label">
                    I agree to the{' '}
                    <a
                      href="/liveterms"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="link"
                    >
                      terms and conditions
                    </a>
                  </label>
                </div>
              </div>
            )}
          </form>
        </div>
        <div className="card-footer">
          {step > 1 && (
            <button type="button" className="btn btn-outline" onClick={() => setStep(step - 1)}>
              <ArrowLeft className="icon" /> Back
            </button>
          )}
          {step < 4 ? (
            <button type="button" className="btn btn-primary" onClick={() => setStep(step + 1)}>
              Next <ArrowRight className="icon" />
            </button>
          ) : (
            <button
              type="button" // Changed from "submit" to "button"
              className="btn btn-primary"
              onClick={()=>UploadLiveProfileInfoHandler()}// Pass the function reference, not a call
              disabled={!agreedToTerms || isLoading}
            >
              {isLoading ? 'Submitting...' : 'Submit'}
            </button>
          )}
        </div>
      </div>
    );
  };
  
  export default RecipePublisherSignup;
  
